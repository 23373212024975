

import { ServerSelect } from "components/elements/select/newSelect/ServerSelect";
import RequestListDTO from "dto/app/requestlist.dto";

import { FromEmailDto } from "dto/notification/fromemail.dto";

import React from "react";
import { FromEmailService } from "services/notification/fromemail.service";


type Props = {
  field?: string;
  value?: number | string;
  setObjectField: (field: string, value: any) => void;
};
const service = new FromEmailService();
const SelectFromEmail: React.FC<Props> = ({
  field = "idfromemail",
  value,
  setObjectField,
}) => {
  const getList = (cb?: any, cbParams?: any, data?: RequestListDTO) => {
    
    service.getList(cb, cbParams, data);
  };

  return (
    <ServerSelect
      processOptions={FromEmailDto.parseToSelectOptions}
      getList={getList}
      field={field}
      setObjectField={setObjectField}
      value={value}
      label="field_fromemail"
    />
  );
};

export { SelectFromEmail };
