import { Box } from "@mui/material";
import { MyButton } from "components/elements/button/MyButton";
import MyTextField from "components/elements/form/mytextfield";

import { FromEmailDto } from "dto/notification/fromemail.dto";
import { useForm } from "hooks/useForm";
import { useResource } from "hooks/useResource";

import React from "react";

import RequiredValidator from "validators/required.validator";

type Props = {
  defaultObject: FromEmailDto;
  onSubmit: (data: FromEmailDto) => void;
  loading: boolean;
};
const FormFromEmail: React.FC<Props> = ({
  defaultObject,
  onSubmit,
  loading,
}) => {
  const { LL } = useResource();

  const [obj, disabled, setObjField, , vResults] = useForm<FromEmailDto>(
    defaultObject,
    RequiredValidator.getValidators([
      "email",
      "password",
      "smtp_host",
      "smtp_port",
    ])
  );

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onSubmit(obj);
  };
  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Box>
          <MyTextField
            field="email"
            _label={LL("email")}
            setObj={setObjField}
            value={obj.email}
            _vresults={vResults}
          />
        </Box>
        <Box mt={3}>
          <MyTextField
            field="password"
            _label={LL("password")}
            setObj={setObjField}
            value={obj.password}
            _vresults={vResults}
          />
        </Box>
        <Box mt={3}>
          <MyTextField
            field="smtp_host"
            _label={LL("smtp_host")}
            setObj={setObjField}
            value={obj.smtp_host}
            _vresults={vResults}
          />
        </Box>
        <Box mt={3}>
          <MyTextField
            field="smtp_port"
            _label={LL("smtp_port")}
            setObj={setObjField}
            value={obj.smtp_port}
            _vresults={vResults}
          />
        </Box>

        <Box mt={3}>
          <MyButton
            type="submit"
            disabled={loading || disabled}
            color="primary"
            fullWidth
            variant="contained"
            size="large"
          >
            {LL("Bt_Submit")}
          </MyButton>
        </Box>
      </form>
    </Box>
  );
};

export { FormFromEmail };
