import React from "react";

import { List, ListItem, Skeleton, Stack, Typography } from "@mui/material";
import { NotificationParamsDto } from "dto/notification/notificationparams.dto";

import RequestListDTO from "dto/app/requestlist.dto";
import { ComponentCommonTools } from "tools/utils/componentcommontools";
import {NotificationParamsService} from "services/notification/notificationparams.service";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import { useList } from "hooks/useList";

const service = new NotificationParamsService();
type Props = {
  idnotification: string;
};
const NotificationParamsList: React.FC<Props> = ({ idnotification }) => {
  const getList = (cb?: any, cbParams?: any, data?: RequestListDTO) => {
    service.getList(cb, cbParams, data);
  };
  const [loading, objects] = useList<NotificationParamsDto>(
    getList,
    new RequestListDTO(
      [RequestFilterDTO.prepareFilter("idnotification", [idnotification])],
      1,
      999999
    ),
    [idnotification]
  );

  const processItem = (item: NotificationParamsDto, i: number) => {
    if (!item) return null;
    if (!item.hasOwnProperty("id")) return null;
    if (!item.id) return null;

    return (
      <ListItem key={i}>
        <Stack direction={"row"} spacing={1}>
          <Typography>
            {ComponentCommonTools.processObjectField(item, "params")}
          </Typography>
          <Typography>
            {ComponentCommonTools.processObjectField(item, "value")}
          </Typography>
        </Stack>
      </ListItem>
    );
  };
  const processList = () => {
    if (!idnotification) return null;
    if (loading) return <Skeleton variant="rectangular" />;
    if (!objects) return null;
    if (!objects.length) return null;

    return (
      <List>
        {objects.map((item: NotificationParamsDto, i: number) => {
          return processItem(item, i);
        })}
      </List>
    );
  };
  return processList();
};

export { NotificationParamsList };
