import React, {
  createContext,
  useCallback,
  useEffect,
  useState,
} from "react";
import IProvider from "interfaces/provider.interface";
import { Loading } from "components/elements/loading/Loading";

import ResultObjectDTO from "dto/app/resultobject.dto";

import { jwtDecode } from "jwt-decode";

import { Config } from "tools/utils/config";
import  LocalStorageTools  from "api/localstorage.api";
import { isTokenExpired } from "tools/utils/commontools";
import GeneralRepository from "repositories/general.repository";
import { JWTDto } from "dto/system/jwt.dto";
import DateTools from "tools/utils/date.tools";
import { UserService } from "services/user/user.service";

type Props = {
  token: string;
  processToken: (token?: string) => void;
};
export const JWTContext = createContext<Props>({
  token: "",
  processToken: () => {},
});

const service = new UserService();

var isRefreshing = false;
var tokenSet = false;

export const JWTProvider: React.FC<IProvider> = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState<string>(processLocalToken());

  const generate = () => {
    if (isRefreshing) return;
    if (!tokenSet) return;
    isRefreshing = true;
    tokenSet = false;
    service.generate(handleProcessToken, {});
  };

  const refresh = () => {
    if (isRefreshing) return;
    if (!tokenSet) return;
    isRefreshing = true;
    tokenSet = false;
    service.refresh(handleProcessToken, {});
  };

  const handleProcessToken = (result: ResultObjectDTO) => {
    // logger("handleProcessToken", result);
    if (!result) return;
    if (result.err) return;
    if (!result.obj) return;
    const obj = result.obj as JWTDto;
    const token = obj.jwttoken.token ?? "";
    // logger("handleProcessToken", token);
    processToken(token);
    isRefreshing = false;
  };

  const processToken = (token?: string) => {
    if (!token) token = "";
    LocalStorageTools.saveValue("token", token);
    setToken(token);
  };

  const checkLoading = useCallback(() => {
    let loading = false;
    if (!token) loading = true;
    // if (!tokenSet) loading = true;
    setLoading(loading);
  }, [token]);

  const getToken = () => {
    if (!token) {
      generate();
    } else if (isTokenExpired(token)) {
      generate();
    } else if (isTokenStillValid(token)) refresh();
  };

  const verifyToken = (token: string) => {
    if (!token) return;
    else if (isTokenExpired(token)) {
      generate();
    } else if (isTokenStillValid(token)) {
      refresh();
    }
  };
  const returnToken = useCallback(() => {
    verifyToken(token);
    return token;
  }, [token]);

  useEffect(() => {
    GeneralRepository.setToken(returnToken);
    tokenSet = true;
  }, [returnToken]);

  useEffect(() => {
    getToken();
  }, []);

  useEffect(() => {
    checkLoading();
  }, [checkLoading]);

  const value = {
    token,
    processToken,
  };

  return loading ? (
    <Loading />
  ) : (
    <JWTContext.Provider value={value}>{children}</JWTContext.Provider>
  );
};

const isTokenStillValid = (token: string) => {
  if (!token) return false;
  const payload = jwtDecode(token);
  if (!payload) return false;
  const exp = payload.exp ?? 0;
  const now = DateTools.getTimeStamp();
  const timeBuffer = Config.TIME_BUFFER_JWT;
  const adjustedNow = now + timeBuffer;
  return exp < adjustedNow;
};

const processLocalToken = () => {
  const token = LocalStorageTools.getValue("token");
  if (!token) return "";
  if (isTokenExpired(token)) return "";
  return token;
};
