import { Box, Stack, IconButton } from "@mui/material";
import Idto from "interfaces/idto.interface";
import React from "react";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EditIcon from "@mui/icons-material/Edit";
import { BreadcrumbDto } from "dto/app/breadcrumb.dto";

import  SpecialTopInfo  from "components/special/SpecialTopInfo";
import { useResource } from "hooks/useResource";
import { MyBreadcrumbs } from "../breadcrumbs/MyBreadcrumbs";
import { MyNavBt } from "components/elements/button/MyNavBt";
import { CommonTools } from "tools/utils/commontools";

type Props = {
  object: Idto;
  mainObject: string;
  mainUrl: string;
  editUrl: string;
  parentType: string;
  parentId: string;
  specialType: string;
  idLanguage?: string;
  anchorTab?: string;
  mainListUrl: string;
  showEditBtn?: boolean;
  children?: React.ReactNode;
  urlListSecond: string;
};
const HeaderDetailSpecial: React.FC<Props> = ({
  object,
  mainObject,
  mainUrl,
  parentType,
  parentId,
  specialType,
  editUrl,
  idLanguage,
  anchorTab = "",
  mainListUrl,
  urlListSecond,
  showEditBtn = true,
  children = null,
}) => {
  const { LL } = useResource();
  // const access = RouteTools.checkRoutePermission(
  //   RouteTools.getPmHash_Update(specialType)
  // );
  // showEditBtn = showEditBtn && access

  return (
    <React.Fragment>
      <MyBreadcrumbs
        childs={BreadcrumbDto.getBreadcrumbDetailSpecial(
          specialType,
          mainObject,
          parentId
        )}
        mainObject={mainObject}
      />

      <Box
        sx={{
          flexDirection: "row",
          display: "flex",
          alignItems: "center",
          justifyContent: showEditBtn ? "space-between" : "flex-start",

          width: "100%",
        }}
      >
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"flex-start"}
          spacing={1}
        >
          <Box>
            <MyNavBt
              href={urlListSecond}
              sx={{ ml: "auto" }}
              _hstate={{}}
              _mainurl={mainUrl}
              component={IconButton}
            >
              <ArrowBackIcon />
            </MyNavBt>
          </Box>
          <Box>
            <MyNavBt
              href={mainListUrl}
              sx={{ ml: "auto" }}
              _hstate={{}}
              _mainurl={mainUrl}
              className="detailBtn"
            >
              {LL("back_main_list")}
            </MyNavBt>
          </Box>
        </Stack>
        <Box>
          <SpecialTopInfo
            parentType={parentType}
            parentId={parentId}
            specialtype={specialType}
            suplimentar={"detail"}
            variant="h4"
          sx={{ my: 3 }}
          />
        </Box>
        <Box>{children}</Box>
        {showEditBtn && (
          <Box>
            <MyNavBt
              href={editUrl}
              sx={{ ml: "auto" }}
              _hstate={CommonTools.prepareObjectForUpdate(
                object,
                mainObject,
                anchorTab,
                idLanguage
              )}
              _mainurl={mainUrl}
              component={IconButton}
              className="editBtn"
            >
              <EditIcon />
            </MyNavBt>
          </Box>
        )}
      </Box>
    </React.Fragment>
  );
};

export { HeaderDetailSpecial };
