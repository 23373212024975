import { Box, IconButton, Skeleton, Stack } from "@mui/material";
import React, { useState } from "react";
import { Add, Remove } from "@mui/icons-material";
import { AddressDto, AddressFormDto } from "dto/nomenclature/address.dto";
import { AddressService } from "services/nomenclature/address.service";
import ResultObjectDTO from "dto/app/resultobject.dto";
import { CommonTools } from "tools/utils/commontools";

import RequestListDTO from "dto/app/requestlist.dto";
import { useList } from "hooks/useList";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import { Status } from "tools/types/status";
import { FormInlineAddress } from "./FormInlineAddress";
import { MySelect } from "components/elements/select/newSelect/MySelect";
import { useResource } from "hooks/useResource";

type Props = {
  value: string;
  setObjectField: (field: string, value: string) => void;
  field: string;
  disabled?: boolean;
  idUser: string;
};
const service = new AddressService();
const AddressContract: React.FC<Props> = ({
  value,
  setObjectField,
  field,
  disabled = false,
  idUser,
}) => {
  const { LL } = useResource();
  const [addAddress, setAddAddress] = useState(false);
  const [loadingAdd, setLoadingAdd] = useState(false);
  const handleToggle = () => {
    setAddAddress(!addAddress);
  };

  const handleSubmit = (obj: AddressFormDto) => {
    if (!idUser) return;
    obj.iduser = idUser;
    setLoadingAdd(true);
    service.add(handleResult, {}, obj);
  };
  const handleResult = (result: ResultObjectDTO) => {
    if (!result) return;
    if (result.err) return;
    if (!result.obj) return;
    const id = CommonTools.processObjectField(result, ["obj", "id"]);
    addToList(result.obj);
    setObjectField(field, id);
    setAddAddress(false);
    setLoadingAdd(false);
  };

  const getList = (cb?: any, cbParams?: any, data?: RequestListDTO) => {
    if (!idUser) return;
    if (!data) data = new RequestListDTO();

    service.getList(cb, cbParams, data);
  };

  const addToList = (obj: AddressDto) => {
    if (!obj) return;
    if (!obj.id) return;
    if (!objects) return;
    setObjects([...objects, obj]);
  };
  const [loading, objects, , , setObjects] = useList<AddressDto>(
    getList,
    new RequestListDTO(
      [
        RequestFilterDTO.prepareFilter("status", [Status.ACTIVE.toString()]),
        RequestFilterDTO.prepareFilter("iduser", [idUser]),
      ],
      1,
      -1
    ),
    [idUser],
    true
  );

  if (!idUser) return null;
  if (loading) return <Skeleton variant="rectangular" height={50} />;
  if (!objects) return null;

  return (
    <Box>
      <Stack direction={"row"}>
      <MySelect
        options={AddressDto.parseToSelectOptions(objects)}
        _label={LL("address")}
        setObjectField={setObjectField}
        field={field}
        value={value}
        disabled={disabled}
      />

      <Box>
        <IconButton onClick={handleToggle}>
          {addAddress ? <Remove /> : <Add />}
        </IconButton>
      </Box>
      </Stack>
      <Box mt={3}>
        {addAddress ? (
          <Box>
            <FormInlineAddress
              defaultObj={new AddressFormDto()}
              onSubmit={handleSubmit}
              loading={loadingAdd}
            />
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};

export { AddressContract };
