import SelectOptions from "dto/app/selectoptions.dto";
import TbFilterListDTO from "dto/app/tbfilterlist.dto";
import { DetailObject } from "interfaces/detailobject.interface";
import Idto from "interfaces/idto.interface";

import { Types } from "tools/types/types";
import TbFilterDTO from "dto/app/tbfilter.dto";
import { ComponentCommonTools } from "tools/utils/componentcommontools";
import FilterTools from "tools/utils/filtertools";
import { CommonTools } from "tools/utils/commontools";
import { ListColumns } from "tools/utils/listcolumns.tool";

export class FromEmailDto implements Idto {
  id?: string;
  email?: string;
  password?: string;
  smtp_host?: string;
  smtp_port?: number;

  constructor(
    id?:  string,
    email?: string,
    password?: string,
    smtp_host?: string,
    smtp_port?: number
  ) {
    this.id = id || "";
    this.email = email || "";
    this.password = password || "";
    this.smtp_host = smtp_host || "";
    this.smtp_port = smtp_port || 25;
  }

  static getDetailFields(): Array<DetailObject> {
    return [
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "email",
        value: ["email"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "password",
        value: ["password"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "smtp_host",
        value: ["smtp_host"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "smtp_port",
        value: ["smtp_port"],
      },
    ];
  }

  static getColumns(
    LL: (str: string) => void,
    mainObj: string,
    deleteFun?: (obj: any) => void,
    cbParent?: any,
    specialType?: string
  ): Array<any> {
    return [
      ComponentCommonTools.columns_GetCheck(),
      ComponentCommonTools.columns_StandardColumn(LL, "email"),
      ComponentCommonTools.columns_StandardColumn(LL, "smtp_host"),
      ComponentCommonTools.columns_StandardColumn(LL, "smtp_port"),
      ListColumns.columnSendEmail(LL),
      ComponentCommonTools.columns_ActionsColumn(
        LL,
        mainObj,
        deleteFun,
        ["edit", "detail", "delete"],
        ["detail"],
        cbParent,
        specialType
      ),
    ];
  }

  static getFilters(): TbFilterListDTO {
    const obj = new TbFilterListDTO();
    let t: TbFilterDTO;

    t = new TbFilterDTO("search", FilterTools.fiterTypeText);
    obj.addFilter(t);
    return obj;
  }

  static parseToSelectOptions(data: Array<FromEmailDto>): Array<SelectOptions> {
    if (!data) return [];
    if (!Array.isArray(data)) return [];
    if (!data.length) return [];
    let result: Array<SelectOptions> = [];
    data.forEach((element) => {
      result.push(FromEmailDto.parseOption(element));
    });
    return result;
  }

  static parseOption = (obj: FromEmailDto): SelectOptions => {
    return new SelectOptions(
      CommonTools.processObjectField(obj, ["id"]),
      CommonTools.processObjectField(obj, ["email"])
    );
  };
}
