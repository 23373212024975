import { IconButton, Stack, Tooltip } from "@mui/material";
import React, { useState } from "react";


import { Send } from "@mui/icons-material";
import { FromEmailDto } from "dto/notification/fromemail.dto";
import { DialogTestEmail } from "./DialogTestEmail";
import { CommonTools } from "tools/utils/commontools";
import { useResource } from "hooks/useResource";

type Props = {
  object: FromEmailDto;
};

const ColumnSendEmail: React.FC<Props> = ({ object }) => {
  const { LL } = useResource();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const id = CommonTools.processObjectField(object, ["id"]);
  if (!object) return <></>;
  return (
    <Stack
      direction={"column"}
      alignItems={"center"}
      justifyContent={"center"}
      sx={{
        width: "100%",
        height: "100%",
      }}
      onClick={(e) => e.stopPropagation()}
    >
      <Stack
        direction={"row"}
        spacing={2}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <IconButton onClick={handleOpen}>
          <Tooltip title={LL("Send_Email")} placement="right">
            <Send />
          </Tooltip>
        </IconButton>
        <DialogTestEmail
          open={open}
          handleClose={handleClose}
          idFromEmail={id ? id : undefined}
        />
      </Stack>
    </Stack>
  );
};

export { ColumnSendEmail };
