import { Box, Stack, IconButton } from "@mui/material";
import Idto from "interfaces/idto.interface";
import React from "react";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EditIcon from "@mui/icons-material/Edit";
import { BreadcrumbDto } from "dto/app/breadcrumb.dto";
import SpecialTopInfo from "components/special/SpecialTopInfo";
import { CommonTools } from "tools/utils/commontools";
import { MyBreadcrumbs } from "../breadcrumbs/MyBreadcrumbs";
import { MyNavBt } from "components/elements/button/MyNavBt";
import { useResource } from "hooks/useResource";


type Props = {
  object: Idto;
  mainObject: string;
  mainUrl: string;
  editUrl: string;
  parentType: string;
  parentId: string;
  specialType: string;
  firstLevelParentId: string;
  firstLevelParentType: string;
  idLanguage?: string;
  anchorTab?: string;
  urlThirdList: string;
  urlSecondList: string;
  urlList: string;
};
const HeaderDetailSpecialThirdLevel: React.FC<Props> = ({
  object,
  mainObject,
  mainUrl,
  parentType,
  parentId,
  specialType,
  editUrl,
  idLanguage,
  anchorTab = "",
  firstLevelParentId,
  firstLevelParentType,
  urlThirdList,
  urlSecondList,
  urlList
}) => {
    const {LL} = useResource();
  return (
    <React.Fragment>
      <MyBreadcrumbs
        childs={BreadcrumbDto.getBreadcrumbDetailThirdLevel(
          firstLevelParentType,
          firstLevelParentId,
          parentType,
          parentId,
          specialType
        )}
        mainObject={mainObject}
      />

      <Box
        sx={{
          flexDirection: "row",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",

          width: "100%",
        }}
      >
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"flex-start"}
          spacing={1}
        >
          <Box>
            <MyNavBt
              href={urlThirdList}
              sx={{ ml: "auto" }}
              _hstate={{}}
              _mainurl={mainUrl}
              component={IconButton}
            >
              <ArrowBackIcon />
            </MyNavBt>
          </Box>
          <Box>
            <MyNavBt
              href={urlList}
              sx={{ ml: "auto" }}
              _hstate={{}}
              _mainurl={mainUrl}
              className="detailBtn"
            >
              {LL("back_main_list")}
            </MyNavBt>
          </Box>
          <Box>
            <MyNavBt
              href={urlSecondList}
              sx={{ ml: "auto" }}
              _hstate={{}}
              _mainurl={mainUrl}
              className="detailBtn"
              >
                {LL("back_second_list")}
            </MyNavBt>
          </Box>
        </Stack>
        <Box>
          <SpecialTopInfo
            parentType={parentType}
            parentId={parentId}
            specialtype={specialType}
            suplimentar={"detail"}
          />
        </Box>
        <Box>
          <MyNavBt
            href={editUrl}
            sx={{ ml: "auto" }}
            _hstate={CommonTools.prepareObjectForUpdate(
              object,
              mainObject,
              anchorTab,
              idLanguage
            )}
            _mainurl={mainUrl}
            component={IconButton}
            className="editBtn"
          >
            <EditIcon />
          </MyNavBt>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export { HeaderDetailSpecialThirdLevel };
