import React, { useEffect, useState } from "react";
import { useResource } from "hooks/useResource";
import { HomePage } from "../../pages/general/HomePage";
import { Login } from "pages/general/Login";
import { NotFound } from "../../pages/general/NotFound";
import { Forbidden } from "../../pages/general/Forbidden";
import { useLocation } from "react-router-dom";

import { User } from "pages/user/user/User";
import { DetailUser } from "pages/user/user/DetailUser";
import { AddUser } from "pages/user/user/AddUser";
import { EditUser } from "pages/user/user/EditUser";

import { Student } from "pages/user/student/Student";
import { DetailStudent } from "pages/user/student/DetailStudent";
import { AddStudent } from "pages/user/student/AddStudent";
import { EditStudent } from "pages/user/student/EditStudent";

import { Teacher } from "pages/user/teacher/Teacher";
import { DetailTeacher } from "pages/user/teacher/DetailTeacher";
import { AddTeacher } from "pages/user/teacher/AddTeacher";
import { EditTeacher } from "pages/user/teacher/EditTeacher";

import { TeacherFAQ } from "pages/user/teacherfaq/TeacherFAQ";
import { DetailTeacherFAQ } from "pages/user/teacherfaq/DetailTeacherFAQ";
import { AddTeacherFAQ } from "pages/user/teacherfaq/AddTeacherFAQ";
import { EditTeacherFAQ } from "pages/user/teacherfaq/EditTeacherFAQ";

import { Testimonial } from "pages/user/testimonial/Testimonial";
import { DetailTestimonial } from "pages/user/testimonial/DetailTestimonial";
import { AddTestimonial } from "pages/user/testimonial/AddTestimonial";
import { EditTestimonial } from "pages/user/testimonial/EditTestimonial";

import { Role } from "pages/user/role/Role";
import { DetailRole } from "pages/user/role/DetailRole";
import { AddRole } from "pages/user/role/AddRole";
import { EditRole } from "pages/user/role/EditRole";

import { Notification } from "pages/notification/notification/Notification";
import { DetailNotification } from "pages/notification/notification/DetailNotification";

import { TeacherRequest } from "pages/user/teacherrequest/TeacherRequest";
import { DetailTeacherRequest } from "pages/user/teacherrequest/DetailTeacherRequest";
import { EditTeacherRequest } from "pages/user/teacherrequest/EditTeacherRequest";

import { File } from "pages/system/file/File";
import { DetailFile } from "pages/system/file/DetailFile";

import { PricePolicy } from "pages/finance/pricepolicy/PricePolicy";
import { DetailPricePolicy } from "pages/finance/pricepolicy/DetailPricePolicy";
import { AddPricePolicy } from "pages/finance/pricepolicy/AddPricePolicy";
import { EditPricePolicy } from "pages/finance/pricepolicy/EditPricePolicy";

import { Filter } from "pages/course/filter/Filter";
import { DetailFilter } from "pages/course/filter/DetailFilter";
import { AddFilter } from "pages/course/filter/AddFilter";
import { EditFilter } from "pages/course/filter/EditFilter";

import { FilterDictionary } from "pages/course/filterdictionary/FilterDictionary";
import { DetailFilterDictionary } from "pages/course/filterdictionary/DetailFilterDictionary";
import { AddFilterDictionary } from "pages/course/filterdictionary/AddFilterDictionary";
import { EditFilterDictionary } from "pages/course/filterdictionary/EditFilterDictionary";

import { CourseCategory } from "pages/course/coursecategory/CourseCategory";
import { DetailCourseCategory } from "pages/course/coursecategory/DetailCourseCategory";
import { AddCourseCategory } from "pages/course/coursecategory/AddCourseCategory";
import { EditCourseCategory } from "pages/course/coursecategory/EditCourseCategory";

import { Course } from "pages/course/course/Course";
import { DetailCourse } from "pages/course/course/DetailCourse";
import { AddCourse } from "pages/course/course/AddCourse";
import { EditCourse } from "pages/course/course/EditCourse";

import { CourseTeacherRequest } from "pages/course/courseteacherrequest/CourseTeacherRequest";
import { DetailCourseTeacherRequest } from "pages/course/courseteacherrequest/DetailCourseTeacherRequest";
import { EditCourseTeacherRequest } from "pages/course/courseteacherrequest/EditCourseTeacherRequest";

import { Transaction } from "pages/finance/transaction/Transaction";

import { CurrencyRate } from "pages/finance/currencyrate/CurrencyRate";

import { RecommendationPrice } from "pages/finance/recommendationprice/RecommendationPrice";
import { DetailRecommendationPrice } from "pages/finance/recommendationprice/DetailRecommendationPrice";
import { AddRecommendationPrice } from "pages/finance/recommendationprice/AddRecommendationPrice";
import { EditRecommendationPrice } from "pages/finance/recommendationprice/EditRecommendationPrice";

import { PricePolicyLinkTeacher } from "pages/finance/pricepolicylinkteacher/PricePolicyLinkTeacher";
import { DetailPricePolicyLinkTeacher } from "pages/finance/pricepolicylinkteacher/DetailPricePolicyLinkTeacher";
import { AddPricePolicyLinkTeacher } from "pages/finance/pricepolicylinkteacher/AddPricePolicyLinkTeacher";
import { EditPricePolicyLinkTeacher } from "pages/finance/pricepolicylinkteacher/EditPricePolicyLinkTeacher";

import { Currency } from "pages/finance/currency/Currency";
import { DetailCurrency } from "pages/finance/currency/DetailCurrency";
import { AddCurrency } from "pages/finance/currency/AddCurrency";
import { EditCurrency } from "pages/finance/currency/EditCurrency";

import { Journal } from "pages/system/journal/Journal";
import { DetailJournal } from "pages/system/journal/DetailJournal";

import {
  Country,
  AddCountry,
  DetailCountry,
  EditCountry,
} from "pages/nomenclature/Country";

import {
  TypeBlogCategory,
  AddTypeBlogCategory,
  DetailTypeBlogCategory,
  EditTypeBlogCategory,
} from "pages/nomenclature/TypeBlogCategory";

import { TicketCategory } from "pages/support/ticketcategory/TicketCategory";
import { AddTicketCategory } from "pages/support/ticketcategory/AddTicketCategory";
import { DetailTicketCategory } from "pages/support/ticketcategory/DetailTicketCategory";
import { EditTicketCategory } from "pages/support/ticketcategory/EditTicketCategory";

import { Ticket } from "pages/support/ticket/Ticket";
import { DetailTicket } from "pages/support/ticket/DetailTicket";

import { Locality } from "pages/static/locality/Locality";
import { AddLocality } from "pages/static/locality/AddLocality";
import { DetailLocality } from "pages/static/locality/DetailLocality";
import { EditLocality } from "pages/static/locality/EditLocality";

import {
  TypeCourse,
  AddTypeCourse,
  DetailTypeCourse,
  EditTypeCourse,
} from "pages/nomenclature/TypeCourse";

import {
  TypeDiscipline,
  AddTypeDiscipline,
  DetailTypeDiscipline,
  EditTypeDiscipline,
} from "pages/nomenclature/TypeDiscipline";

import {
  TypeExperience,
  AddTypeExperience,
  DetailTypeExperience,
  EditTypeExperience,
} from "pages/nomenclature/TypeExperience";

import {
  TypeFilter,
  AddTypeFilter,
  DetailTypeFilter,
  EditTypeFilter,
} from "pages/nomenclature/TypeFilter";

import {
  TypeHardware,
  AddTypeHardware,
  DetailTypeHardware,
  EditTypeHardware,
} from "pages/nomenclature/TypeHardware";

import {
  TypeInternetConnection,
  AddTypeInternetConnection,
  DetailTypeInternetConnection,
  EditTypeInternetConnection,
} from "pages/nomenclature/TypeInternetConnection";

import {
  TypeLesson,
  AddTypeLesson,
  DetailTypeLesson,
  EditTypeLesson,
} from "pages/nomenclature/TypeLesson";

import {
  TypeLocality,
  AddTypeLocality,
  DetailTypeLocality,
  EditTypeLocality,
} from "pages/nomenclature/TypeLocality";

import {
  TypeOccupation,
  AddTypeOccupation,
  DetailTypeOccupation,
  EditTypeOccupation,
} from "pages/nomenclature/TypeOccupation";

import {
  TypePhone,
  AddTypePhone,
  DetailTypePhone,
  EditTypePhone,
} from "pages/nomenclature/TypePhone";

import {
  TypePrice,
  AddTypePrice,
  DetailTypePrice,
  EditTypePrice,
} from "pages/nomenclature/TypePrice";

import {
  TypeProfessionalismLevel,
  AddTypeProfessionalismLevel,
  DetailTypeProfessionalismLevel,
  EditTypeProfessionalismLevel,
} from "pages/nomenclature/TypeProfessionalismLevel";

import {
  TypeSocial,
  AddTypeSocial,
  DetailTypeSocial,
  EditTypeSocial,
} from "pages/nomenclature/TypeSocial";

import {
  TypeStudies,
  AddTypeStudies,
  DetailTypeStudies,
  EditTypeStudies,
} from "pages/nomenclature/TypeStudies";

import {
  TypeSpecialty,
  AddTypeSpecialty,
  DetailTypeSpecialty,
  EditTypeSpecialty,
} from "pages/nomenclature/TypeSpecialty";

import {
  TypeTargetGroup,
  AddTypeTargetGroup,
  DetailTypeTargetGroup,
  EditTypeTargetGroup,
} from "pages/nomenclature/TypeTargetGroup";
import {
  TypeGender,
  AddTypeGender,
  DetailTypeGender,
  EditTypeGender,
} from "pages/nomenclature/TypeGender";

import { SpecialGallery } from "components/special/gallery/SpecialGallery";
import { SpecialGalleryAdd } from "components/special/gallery/SpecialGalleryAdd";
import { SpecialGalleryEdit } from "components/special/gallery/SpecialGalleryEdit";
import { SpecialGalleryDetail } from "components/special/gallery/SpecialGalleryDetail";

import { SpecialAttachment } from "components/special/attachment/SpecialAttachment";
import { SpecialAttachmentAdd } from "components/special/attachment/SpecialAttachmentAdd";
import { SpecialAttachmentEdit } from "components/special/attachment/SpecialAttachmentEdit";
import { SpecialAttachmentDetail } from "components/special/attachment/SpecialAttachmentDetail";

import { SpecialVideo } from "components/special/video/SpecialVideo";
import { SpecialVideoAdd } from "components/special/video/SpecialVideoAdd";
import { SpecialVideoEdit } from "components/special/video/SpecialVideoEdit";
import { SpecialVideoDetail } from "components/special/video/SpecialVideoDetail";

import { FAQ } from "pages/static/faq/FAQ";
import { DetailFAQ } from "pages/static/faq/DetailFAQ";
import { AddFAQ } from "pages/static/faq/AddFAQ";
import { EditFAQ } from "pages/static/faq/EditFAQ";

import { Blog } from "pages/static/blog/Blog";
import { DetailBlog } from "pages/static/blog/DetailBlog";
import { AddBlog } from "pages/static/blog/AddBlog";
import { EditBlog } from "pages/static/blog/EditBlog";

import { SupportArticle } from "pages/static/supportarticle/SupportArticle";
import { DetailSupportArticle } from "pages/static/supportarticle/DetailSupportArticle";
import { AddSupportArticle } from "pages/static/supportarticle/AddSupportArticle";
import { EditSupportArticle } from "pages/static/supportarticle/EditSupportArticle";

import { BlogCategory } from "pages/static/blogcategory/BlogCategory";
import { DetailBlogCategory } from "pages/static/blogcategory/DetailBlogCategory";
import { AddBlogCategory } from "pages/static/blogcategory/AddBlogCategory";
import { EditBlogCategory } from "pages/static/blogcategory/EditBlogCategory";

import { SiteSocial } from "pages/static/sitesocial/SiteSocial";
import { DetailSiteSocial } from "pages/static/sitesocial/DetailSiteSocial";
import { AddSiteSocial } from "pages/static/sitesocial/AddSiteSocial";
import { EditSiteSocial } from "pages/static/sitesocial/EditSiteSocial";

import { Language } from "pages/static/language/Language";
import { AddLanguage } from "pages/static/language/AddLanguage";
import { DetailLanguage } from "pages/static/language/DetailLanguage";
import { EditLanguage } from "pages/static/language/EditLanguage";

import { SupportArticleCategory } from "pages/static/supportarticlecategory/SupportArticleCategory";
import { DetailSupportArticleCategory } from "pages/static/supportarticlecategory/DetailSupportArticleCategory";
import { AddSupportArticleCategory } from "pages/static/supportarticlecategory/AddSupportArticleCategory";
import { EditSupportArticleCategory } from "pages/static/supportarticlecategory/EditSupportArticleCategory";

import { Label } from "pages/static/label/Label";
import { DetailLabel } from "pages/static/label/DetailLabel";
import { EditLabel } from "pages/static/label/EditLabel";

import { Menu } from "pages/static/menu/Menu";
import { DetailMenu } from "pages/static/menu/DetailMenu";
import { AddMenu } from "pages/static/menu/AddMenu";
import { EditMenu } from "pages/static/menu/EditMenu";

import { Page } from "pages/static/page/Page";
import { DetailPage } from "pages/static/page/DetailPage";
import { AddPage } from "pages/static/page/AddPage";
import { EditPage } from "pages/static/page/EditPage";

import { Advertisement } from "pages/static/advertisement/Advertisement";
import { DetailAdvertisement } from "pages/static/advertisement/DetailAdvertisement";
import { AddAdvertisement } from "pages/static/advertisement/AddAdvertisement";
import { EditAdvertisement } from "pages/static/advertisement/EditAdvertisement";

import { SiteConfig } from "pages/static/siteconfig/SiteConfig";
import { DetailSiteConfig } from "pages/static/siteconfig/DetailSiteConfig";
import { EditSiteConfig } from "pages/static/siteconfig/EditSiteConfig";

import { SiteConfigFile } from "pages/static/siteconfigfile/SiteConfigFile";
import { AddSiteConfigFile } from "pages/static/siteconfigfile/AddSiteConfigFile";
import { DetailSiteConfigFile } from "pages/static/siteconfigfile/DetailSiteConfigFile";
import { EditSiteConfigFile } from "pages/static/siteconfigfile/EditSiteConfigFile";
import { SpecialUserRole } from "components/special/userrole/SpecialUserRole";
import { SpecialUserRoleAdd } from "components/special/userrole/SpecialUserRoleAdd";
import { SpecialUserRoleDetail } from "components/special/userrole/SpecialUserRoleDetail";

import { SpecialPhone } from "components/special/phone/SpecialPhone";
import { SpecialPhoneAdd } from "components/special/phone/SpecialPhoneAdd";
import { SpecialPhoneDetail } from "components/special/phone/SpecialPhoneDetail";
import { SpecialPhoneEdit } from "components/special/phone/SpecialPhoneEdit";

import { SpecialSocial } from "components/special/social/SpecialSocial";
import { SpecialSocialAdd } from "components/special/social/SpecialSocialAdd";
import { SpecialSocialDetail } from "components/special/social/SpecialSocialDetail";
import { SpecialSocialEdit } from "components/special/social/SpecialSocialEdit";

import { SpecialAddress } from "components/special/address/SpecialAddress";
import { SpecialAddressAdd } from "components/special/address/SpecialAddressAdd";
import { SpecialAddressDetail } from "components/special/address/SpecialAddressDetail";
import { SpecialAddressEdit } from "components/special/address/SpecialAddressEdit";

import { SpecialCourseEnrollment } from "components/special/courseenrollment/SpecialCourseEnrollment";
import { SpecialCourseEnrollmentAdd } from "components/special/courseenrollment/SpecialCourseEnrollmentAdd";
import { SpecialCourseEnrollmentDetail } from "components/special/courseenrollment/SpecialCourseEnrollmentDetail";
import { SpecialCourseEnrollmentEdit } from "components/special/courseenrollment/SpecialCourseEnrollmentEdit";

import { SpecialLinkCourseCategory } from "components/special/linkcoursecategory/SpecialLinkCourseCategory";
import { SpecialLinkCourseCategoryAdd } from "components/special/linkcoursecategory/SpecialLinkCourseCategoryAdd";
import { SpecialLinkCourseCategoryDetail } from "components/special/linkcoursecategory/SpecialLinkCourseCategoryDetail";

import { SpecialCourseFilter } from "components/special/coursefilter/SpecialCourseFilter";
import { SpecialCourseFilterAdd } from "components/special/coursefilter/SpecialCourseFilterAdd";
import { SpecialCourseFilterDetail } from "components/special/coursefilter/SpecialCourseFilterDetail";
import { SpecialCourseFilterEdit } from "components/special/coursefilter/SpecialCourseFilterEdit";

import { SpecialLesson } from "components/special/lesson/SpecialLesson";
import { SpecialLessonAdd } from "components/special/lesson/SpecialLessonAdd";
import { SpecialLessonDetail } from "components/special/lesson/SpecialLessonDetail";
import { SpecialLessonEdit } from "components/special/lesson/SpecialLessonEdit";

import { ThirdLevelSpecialAttachment } from "components/specialthirdlevel/attachment/ThirdLevelSpecialAttachment";
import { ThirdLevelSpecialAttachmentAdd } from "components/specialthirdlevel/attachment/ThirdLevelSpecialAttachmentAdd";
import { ThirdLevelSpecialAttachmentDetail } from "components/specialthirdlevel/attachment/ThirdLevelSpecialAttachmentDetail";
import { ThirdLevelSpecialAttachmentEdit } from "components/specialthirdlevel/attachment/ThirdLevelSpecialAttachmentEdit";

import { ThirdLevelSpecialGallery } from "components/specialthirdlevel/gallery/ThirdLevelSpecialGallery";
import { ThirdLevelSpecialGalleryAdd } from "components/specialthirdlevel/gallery/ThirdLevelSpecialGalleryAdd";
import { ThirdLevelSpecialGalleryEdit } from "components/specialthirdlevel/gallery/ThirdLevelSpecialGalleryEdit";
import { ThirdLevelSpecialGalleryDetail } from "components/specialthirdlevel/gallery/ThirdLevelSpecialGalleryDetail";

import { ThirdLevelSpecialVideo } from "components/specialthirdlevel/video/ThirdLevelSpecialVideo";
import { ThirdLevelSpecialVideoAdd } from "components/specialthirdlevel/video/ThirdLevelSpecialVideoAdd";
import { ThirdLevelSpecialVideoEdit } from "components/specialthirdlevel/video/ThirdLevelSpecialVideoEdit";
import { ThirdLevelSpecialVideoDetail } from "components/specialthirdlevel/video/ThirdLevelSpecialVideoDetail";
import { RouteTools } from "tools/utils/routetools";
import { useReactPath } from "hooks/useReactPath";

import { SpecialTest } from "components/special/test/SpecialTest";
import { SpecialTestAdd } from "components/special/test/SpecialTestAdd";
import { SpecialTestDetail } from "components/special/test/SpecialTestDetail";
import { SpecialTestEdit } from "components/special/test/SpecialTestEdit";
import { SpecialPermission } from "components/special/permission/SpecialPermission";
import { ProfilePage } from "pages/general/ProfilePage";
import { Newsletter } from "pages/user/newsletter/Newsletter";
import { DetailNewsletter } from "pages/user/newsletter/DetailNewsletter";
import { AddFromEmail } from "pages/notification/fromemail/AddFromEmail";
import { FromEmail } from "pages/notification/fromemail/FromEmail";
import { EditFromEmail } from "pages/notification/fromemail/EditFromEmail";
import { DetailFromEmail } from "pages/notification/fromemail/DetailFromEmail";
import { EmailTemplate } from "pages/notification/emailtemplate/EmailTemplate";
import { DetailEmailTemplate } from "pages/notification/emailtemplate/DetailEmailTemplate";
import { AddEmailTemplate } from "pages/notification/emailtemplate/AddEmailTemplate";
import { EditEmailTemplate } from "pages/notification/emailtemplate/EditEmailTemplate";
import { AddAppNotifyTemplate } from "pages/notification/appnotifytemplate/AddAppNotifyTemplate";
import { AppNotifyTemplate } from "pages/notification/appnotifytemplate/AppNotifyTemplate";
import { DetailAppNotifyTemplate } from "pages/notification/appnotifytemplate/DetailAppNotifyTemplate";
import { EditAppNotifyTemplate } from "pages/notification/appnotifytemplate/EditAppNotifyTemplate";
import { AddSiteNotifyTemplate } from "pages/notification/sitenotifytemplate/AddSiteNotifyTemplate";
import { DetailSiteNotifyTemplate } from "pages/notification/sitenotifytemplate/DetailSiteNotifyTemplate";
import { EditSiteNotifyTemplate } from "pages/notification/sitenotifytemplate/EditSiteNotifyTemplate";
import { SiteNotifyTemplate } from "pages/notification/sitenotifytemplate/SiteNotifyTemplate";

import { SmsTemplate } from "pages/notification/smstemplate/SmsTemplate";
import { AddSmsTemplate } from "pages/notification/smstemplate/AddSmsTemplate";
import { DetailSmsTemplate } from "pages/notification/smstemplate/DetailSmsTemplate";
import { EditSmsTemplate } from "pages/notification/smstemplate/EditSmsTemplate";

const specialModules = [
  "gallery",
  "attachment",
  "video",
  "userrole",
  "phone",
  "social",
  "address",
  "courseenrollment",
  "linkcoursecategory",
  "coursefilter",
  "lesson",
  "test",
  "permission",
];

const thirdLevelSpecialModules = [
  "thirdlevelattachment",
  "thirdlevelgallery",
  "thirdlevelvideo",
];

const MainLayout: React.FC = () => {
  const components: any = {
    Newsletter,
    DetailNewsletter,

    SpecialPermission,

    SpecialTest,
    SpecialTestAdd,
    SpecialTestDetail,
    SpecialTestEdit,

    ThirdLevelSpecialVideo,
    ThirdLevelSpecialVideoAdd,
    ThirdLevelSpecialVideoEdit,
    ThirdLevelSpecialVideoDetail,

    ThirdLevelSpecialGallery,
    ThirdLevelSpecialGalleryAdd,
    ThirdLevelSpecialGalleryEdit,
    ThirdLevelSpecialGalleryDetail,

    ThirdLevelSpecialAttachment,
    ThirdLevelSpecialAttachmentAdd,
    ThirdLevelSpecialAttachmentDetail,
    ThirdLevelSpecialAttachmentEdit,

    SpecialLesson,
    SpecialLessonAdd,
    SpecialLessonDetail,
    SpecialLessonEdit,

    SpecialCourseFilter,
    SpecialCourseFilterAdd,
    SpecialCourseFilterDetail,
    SpecialCourseFilterEdit,

    SpecialLinkCourseCategory,
    SpecialLinkCourseCategoryAdd,
    SpecialLinkCourseCategoryDetail,

    SpecialCourseEnrollment,
    SpecialCourseEnrollmentAdd,
    SpecialCourseEnrollmentDetail,
    SpecialCourseEnrollmentEdit,

    SpecialAddress,
    SpecialAddressAdd,
    SpecialAddressDetail,
    SpecialAddressEdit,

    SpecialSocial,
    SpecialSocialAdd,
    SpecialSocialDetail,
    SpecialSocialEdit,

    SpecialPhone,
    SpecialPhoneAdd,
    SpecialPhoneDetail,
    SpecialPhoneEdit,

    SpecialGallery,
    SpecialGalleryAdd,
    SpecialGalleryEdit,
    SpecialGalleryDetail,

    SpecialAttachment,
    SpecialAttachmentAdd,
    SpecialAttachmentEdit,
    SpecialAttachmentDetail,

    SpecialVideo,
    SpecialVideoAdd,
    SpecialVideoEdit,
    SpecialVideoDetail,

    User,
    DetailUser,
    AddUser,
    EditUser,

    Student,
    DetailStudent,
    AddStudent,
    EditStudent,

    Teacher,
    DetailTeacher,
    AddTeacher,
    EditTeacher,

    TeacherFAQ,
    DetailTeacherFAQ,
    AddTeacherFAQ,
    EditTeacherFAQ,

    Testimonial,
    DetailTestimonial,
    AddTestimonial,
    EditTestimonial,

    Role,
    DetailRole,
    AddRole,
    EditRole,

    Notification,
    DetailNotification,

    TeacherRequest,
    DetailTeacherRequest,
    EditTeacherRequest,

    File,
    DetailFile,

    Menu,
    DetailMenu,
    AddMenu,
    EditMenu,

    FAQ,
    DetailFAQ,
    AddFAQ,
    EditFAQ,

    Advertisement,
    DetailAdvertisement,
    AddAdvertisement,
    EditAdvertisement,

    PricePolicy,
    DetailPricePolicy,
    AddPricePolicy,
    EditPricePolicy,

    CurrencyRate,
    Transaction,

    RecommendationPrice,
    DetailRecommendationPrice,
    AddRecommendationPrice,
    EditRecommendationPrice,

    PricePolicyLinkTeacher,
    DetailPricePolicyLinkTeacher,
    AddPricePolicyLinkTeacher,
    EditPricePolicyLinkTeacher,

    Currency,
    DetailCurrency,
    AddCurrency,
    EditCurrency,

    Blog,
    DetailBlog,
    AddBlog,
    EditBlog,

    SupportArticle,
    DetailSupportArticle,
    AddSupportArticle,
    EditSupportArticle,

    SiteSocial,
    DetailSiteSocial,
    AddSiteSocial,
    EditSiteSocial,

    BlogCategory,
    DetailBlogCategory,
    AddBlogCategory,
    EditBlogCategory,

    SupportArticleCategory,
    DetailSupportArticleCategory,
    AddSupportArticleCategory,
    EditSupportArticleCategory,

    TicketCategory,
    DetailTicketCategory,
    AddTicketCategory,
    EditTicketCategory,

    Ticket,
    DetailTicket,

    Page,
    DetailPage,
    AddPage,
    EditPage,

    Language,
    AddLanguage,
    DetailLanguage,
    EditLanguage,

    SiteConfig,
    DetailSiteConfig,
    EditSiteConfig,

    SiteConfigFile,
    AddSiteConfigFile,
    DetailSiteConfigFile,
    EditSiteConfigFile,

    Label,
    DetailLabel,
    EditLabel,

    Journal,
    DetailJournal,

    Country,
    AddCountry,
    DetailCountry,
    EditCountry,

    TypeBlogCategory,
    AddTypeBlogCategory,
    DetailTypeBlogCategory,
    EditTypeBlogCategory,

    Locality,
    AddLocality,
    DetailLocality,
    EditLocality,

    TypeCourse,
    AddTypeCourse,
    DetailTypeCourse,
    EditTypeCourse,

    TypeDiscipline,
    AddTypeDiscipline,
    DetailTypeDiscipline,
    EditTypeDiscipline,

    TypeExperience,
    AddTypeExperience,
    DetailTypeExperience,
    EditTypeExperience,

    TypeFilter,
    AddTypeFilter,
    DetailTypeFilter,
    EditTypeFilter,

    TypeHardware,
    AddTypeHardware,
    DetailTypeHardware,
    EditTypeHardware,

    TypeInternetConnection,
    AddTypeInternetConnection,
    DetailTypeInternetConnection,
    EditTypeInternetConnection,

    TypeLesson,
    AddTypeLesson,
    DetailTypeLesson,
    EditTypeLesson,

    TypeLocality,
    AddTypeLocality,
    DetailTypeLocality,
    EditTypeLocality,

    TypeOccupation,
    AddTypeOccupation,
    DetailTypeOccupation,
    EditTypeOccupation,

    TypePhone,
    AddTypePhone,
    DetailTypePhone,
    EditTypePhone,

    TypePrice,
    AddTypePrice,
    DetailTypePrice,
    EditTypePrice,

    TypeProfessionalismLevel,
    AddTypeProfessionalismLevel,
    DetailTypeProfessionalismLevel,
    EditTypeProfessionalismLevel,

    TypeSocial,
    AddTypeSocial,
    DetailTypeSocial,
    EditTypeSocial,

    TypeStudies,
    AddTypeStudies,
    DetailTypeStudies,
    EditTypeStudies,

    TypeSpecialty,
    AddTypeSpecialty,
    DetailTypeSpecialty,
    EditTypeSpecialty,

    TypeTargetGroup,
    AddTypeTargetGroup,
    DetailTypeTargetGroup,
    EditTypeTargetGroup,

    TypeGender,
    AddTypeGender,
    DetailTypeGender,
    EditTypeGender,

    Filter,
    DetailFilter,
    AddFilter,
    EditFilter,

    FilterDictionary,
    DetailFilterDictionary,
    AddFilterDictionary,
    EditFilterDictionary,

    CourseCategory,
    DetailCourseCategory,
    AddCourseCategory,
    EditCourseCategory,

    Course,
    DetailCourse,
    AddCourse,
    EditCourse,

    CourseTeacherRequest,
    DetailCourseTeacherRequest,
    EditCourseTeacherRequest,

    SpecialUserRole,
    SpecialUserRoleAdd,
    SpecialUserRoleDetail,

    ProfilePage,

    FromEmail,
    AddFromEmail,
    DetailFromEmail,
    EditFromEmail,

    EmailTemplate,
    AddEmailTemplate,
    DetailEmailTemplate,
    EditEmailTemplate,

    SiteNotifyTemplate,
    AddSiteNotifyTemplate,
    DetailSiteNotifyTemplate,
    EditSiteNotifyTemplate,

    AppNotifyTemplate,
    AddAppNotifyTemplate,
    DetailAppNotifyTemplate,
    EditAppNotifyTemplate,

    SmsTemplate,
    AddSmsTemplate,
    EditSmsTemplate,
    DetailSmsTemplate,

  };

  const { currentRoute, setCurrentRoute } = useResource();
  const location = useLocation();
  const pathObject = useReactPath();

  const [_currentpath, setCurrentPath] = useState("");
  const [_mainobject, setMainObject] = useState<any>(false);

  useEffect(() => {
    let routeobj = RouteTools.parseLocation(location);
    routeobj.historystate = pathObject.historystate;

    if (routeobj.url === _currentpath) return;
    setCurrentPath(routeobj.url);

    setCurrentRoute(routeobj);
  }, [pathObject]);

  useEffect(() => {
    if (currentRoute && currentRoute._mainobject) {
      setMainObject(currentRoute._mainobject);
    }
  }, [currentRoute]);

  const processStandardModule_GetSpecial = () => {
    if (!currentRoute) return "";
    const path = currentRoute._paths;

    let specprefix = "";

    if (path.length === 3) {
      //
    } else if (path[3] === "add") {
      specprefix = "Add";
    } else if (path[3] === "edit") {
      specprefix = "Edit";
    } else {
      specprefix = "Detail";
    }

    if (path[2] === "gallery") {
      return "SpecialGallery" + specprefix;
    }

    if (path[2] === "attachment") {
      return "SpecialAttachment" + specprefix;
    }

    if (path[2] === "video") {
      return "SpecialVideo" + specprefix;
    }

    if (path[2] === "userrole") {
      return "SpecialUserRole" + specprefix;
    }

    if (path[2] === "phone") {
      return "SpecialPhone" + specprefix;
    }

    if (path[2] === "social") {
      return "SpecialSocial" + specprefix;
    }

    if (path[2] === "address") {
      return "SpecialAddress" + specprefix;
    }

    if (path[2] === "courseenrollment") {
      return "SpecialCourseEnrollment" + specprefix;
    }

    if (path[2] === "linkcoursecategory") {
      return "SpecialLinkCourseCategory" + specprefix;
    }

    if (path[2] === "coursefilter") {
      return "SpecialCourseFilter" + specprefix;
    }

    if (path[2] === "lesson") {
      return "SpecialLesson" + specprefix;
    }

    if (path[2] === "test") {
      return "SpecialTest" + specprefix;
    }

    if (path[2] === "permission") {
      return "SpecialPermission" + specprefix;
    }
  };

  const processStandardModule_GetSpecialThirdLevel = () => {
    if (!currentRoute) return "";
    const path = currentRoute._paths;

    let specprefix = "";

    if (path.length === 5) {
      //
    } else if (path[5] === "add") {
      specprefix = "Add";
    } else if (path[5] === "edit") {
      specprefix = "Edit";
    } else {
      specprefix = "Detail";
    }

    if (path[4] === "thirdlevelattachment") {
      return "ThirdLevelSpecialAttachment" + specprefix;
    }
    if (path[4] === "thirdlevelgallery") {
      return "ThirdLevelSpecialGallery" + specprefix;
    }

    if (path[4] === "thirdlevelvideo") {
      return "ThirdLevelSpecialVideo" + specprefix;
    }
  };

  const processStandardModule_GetPrefix = () => {
    if (!currentRoute) return "";
    const path = currentRoute._paths;
    if (path.length === 1) return "";
    if (path[1] === "edit") return "Edit";
    if (path[1] === "add") return "Add";

    if (
      path[2] !== undefined &&
      specialModules.includes(path[2]) &&
      !thirdLevelSpecialModules.includes(path[4] ?? "")
    )
      return "Special";

    if (path[4] !== undefined && thirdLevelSpecialModules.includes(path[4])) {
      return "ThirdLevelSpecial";
    }

    return "Detail";
  };

  const processStandardModule_GetComponentName = () => {
    if (_mainobject === "user") return "User";
    if (_mainobject === "student") return "Student";
    if (_mainobject === "teacher") return "Teacher";
    if (_mainobject === "teacherfaq") return "TeacherFAQ";
    if (_mainobject === "testimonial") return "Testimonial";
    if (_mainobject === "role") return "Role";
    if (_mainobject === "notification") return "Notification";
    if (_mainobject === "newsletter") return "Newsletter";
    if (_mainobject === "teacherrequest") return "TeacherRequest";
    if (_mainobject === "file") return "File";

    if (_mainobject === "menu") return "Menu";
    if (_mainobject === "faq") return "FAQ";
    if (_mainobject === "advertisement") return "Advertisement";
    if (_mainobject === "pricepolicy") return "PricePolicy";
    if (_mainobject === "currencyrate") return "CurrencyRate";
    if (_mainobject === "transaction") return "Transaction";
    if (_mainobject === "recommendationprice") return "RecommendationPrice";
    if (_mainobject === "pricepolicylinkteacher")
      return "PricePolicyLinkTeacher";
    if (_mainobject === "currency") return "Currency";
    if (_mainobject === "blog") return "Blog";
    if (_mainobject === "supportarticle") return "SupportArticle";
    if (_mainobject === "sitesocial") return "SiteSocial";
    if (_mainobject === "blogcategory") return "BlogCategory";
    if (_mainobject === "supportarticlecategory")
      return "SupportArticleCategory";
    if (_mainobject === "ticketcategory") return "TicketCategory";
    if (_mainobject === "ticket") return "Ticket";
    if (_mainobject === "page") return "Page";

    if (_mainobject === "language") return "Language";
    if (_mainobject === "siteconfig") return "SiteConfig";
    if (_mainobject === "siteconfigfile") return "SiteConfigFile";
    if (_mainobject === "journal") return "Journal";
    if (_mainobject === "label") return "Label";
    if (_mainobject === "country") return "Country";
    if (_mainobject === "typeblogcategory") return "TypeBlogCategory";
    if (_mainobject === "locality") return "Locality";
    if (_mainobject === "typecourse") return "TypeCourse";
    if (_mainobject === "typediscipline") return "TypeDiscipline";
    if (_mainobject === "typeexperience") return "TypeExperience";
    if (_mainobject === "typefilter") return "TypeFilter";
    if (_mainobject === "typehardware") return "TypeHardware";
    if (_mainobject === "typelesson") return "TypeLesson";
    if (_mainobject === "typeinternetconnection")
      return "TypeInternetConnection";
    if (_mainobject === "typelocality") return "TypeLocality";
    if (_mainobject === "typeoccupation") return "TypeOccupation";
    if (_mainobject === "typephone") return "TypePhone";
    if (_mainobject === "typeprice") return "TypePrice";
    if (_mainobject === "typeprofessionalismlevel")
      return "TypeProfessionalismLevel";
    if (_mainobject === "typesocial") return "TypeSocial";
    if (_mainobject === "typestudies") return "TypeStudies";
    if (_mainobject === "typespecialty") return "TypeSpecialty";
    if (_mainobject === "typetargetgroup") return "TypeTargetGroup";
    if (_mainobject === "typegender") return "TypeGender";
    if (_mainobject === "filter") return "Filter";
    if (_mainobject === "filterdictionary") return "FilterDictionary";
    if (_mainobject === "coursecategory") return "CourseCategory";
    if (_mainobject === "course") return "Course";
    if (_mainobject === "courseteacherrequest") return "CourseTeacherRequest";
    if (_mainobject === "fromemail") return "FromEmail";
    if (_mainobject === "emailtemplate") return "EmailTemplate";
    if (_mainobject === "appnotifytemplate") return "AppNotifyTemplate";
    if (_mainobject === "sitenotifytemplate") return "SiteNotifyTemplate";
    if (_mainobject === "smstemplate") return "SmsTemplate";    

    return "";
  };

  const processStandardModule = () => {
    if (!_mainobject) return "";

    if (RouteTools.modules.indexOf(_mainobject) === -1) return "";

    const prefix = processStandardModule_GetPrefix();
    const cname = processStandardModule_GetComponentName();

    if (prefix === "Special") {
      return processStandardModule_GetSpecial();
    }

    if (prefix === "ThirdLevelSpecial") {
      return processStandardModule_GetSpecialThirdLevel();
    }

    return prefix + cname;
  };

  const processMainObject = () => {
    if (!_mainobject)
      return <NotFound currentRoute={currentRoute} mainObject={_mainobject} />;

    if (_mainobject === "homepage")
      return <HomePage currentRoute={currentRoute} mainObject={_mainobject} />;
    if (_mainobject === "login")
      return <Login currentRoute={currentRoute} mainObject={_mainobject} />;
    if (_mainobject === "profile")
      return (
        <ProfilePage currentRoute={currentRoute} mainObject={_mainobject} />
      );
    if (_mainobject === "forbidden")
      return <Forbidden currentRoute={currentRoute} mainObject={_mainobject} />;
    if (_mainobject === "notfound")
      return <NotFound currentRoute={currentRoute} mainObject={_mainobject} />;

    const tag = processStandardModule() ?? "";
    const DynamicComponent = components[tag] || null;
    if (DynamicComponent)
      return (
        <DynamicComponent
          currentRoute={currentRoute}
          mainObject={_mainobject}
        />
      );

    return <NotFound currentRoute={currentRoute} />;
  };

  return processMainObject();
};

export { MainLayout };
