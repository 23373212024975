import React, { createContext, useEffect, useContext, useState } from "react";
import { io, Socket } from "socket.io-client";
import { JWTContext } from "./JWTProvider";
import IProvider from "interfaces/provider.interface";
import { Config } from "tools/utils/config";

interface SocketContextType {
  socket: Socket | null;
}

export const SocketContext = createContext<SocketContextType>({ socket: null });

export const SocketProvider: React.FC<IProvider> = ({ children }) => {
  const { token } = useContext(JWTContext); // Get the token from JWTContext
  const [connected, setConnected] = useState(false);
  const [socket, setSocket] = useState<Socket | null>(null);
 
  useEffect(() => {
    const socketInstance = io(Config.WS_URL, {
      path: "/ws",
    });

    socketInstance.on("connect", () => {
      setConnected(true);
    });

    socketInstance.on("disconnect", (reason) => {
      setConnected(false);
    });

    setSocket(socketInstance);

    return () => {
      socketInstance.disconnect();
      setSocket(null); 
    };
  }, []); 

  useEffect(() => {
    if (connected && token && socket) {
      socket.emit("register-token", { token, sourse: Config.SITE_IDENTIFIER });
    }
  }, [token, connected, socket]); 

  return (
    <SocketContext.Provider value={{ socket }}>
      {children}
    </SocketContext.Provider>
  );
};

export default SocketProvider;
