import { Box, Grid } from "@mui/material";

import { VideoDto } from "dto/system/video.dto";
import { IPage } from "interfaces/page.interface";
import React from "react";
import { VideoService } from "services/system/video.service";

import { DataDisplayBox } from "components/elements/display/DataDisplayBox";
import { LanguageSelect } from "components/static/language/newLanguageSelect";

import { Loading } from "components/elements/loading/Loading";
import { DetailGrid } from "components/elements/generalcomponents/DetailGrid";
import { useDetailSpecialThirdLevel } from "hooks/useDetailSpecialThirdLevel";
import { HeaderDetailSpecialThirdLevel } from "components/elements/detail/HeaderDetailSpecialThirdLevel";

const service = new VideoService();
const ThirdLevelSpecialVideoDetail: React.FC<IPage> = ({
  currentRoute,
  mainObject,
}) => {
  const get = (id: string, cb: any, cbParams: any, idLanguage?: string) => {
    service.get(id, cb, cbParams, idLanguage);
  };

  const {
    mainUrl,
    editUrl,
    parentType,
    parentId,
    object,
    loading,
    idLanguage,
    setIdLanguage,
    firstLevelParentId,
    firstLevelParentType,
    listUrl,
    listUrlSecond,
    listUrlThird,
  } = useDetailSpecialThirdLevel<VideoDto>(
    "thirdlevelvideo",
    currentRoute,
    get,
    true,
    undefined,
    5
  );

  if (!currentRoute) return null;
  if (!mainObject) return null;
  if (loading) return <Loading />;
  if (!object) return null;

  return (
    <React.Fragment>
      <HeaderDetailSpecialThirdLevel
        object={object}
        mainObject={mainObject}
        mainUrl={mainUrl}
        parentId={parentId}
        parentType={parentType}
        editUrl={editUrl}
        idLanguage={idLanguage}
        specialType="thirdlevelvideo"
        firstLevelParentId={firstLevelParentId}
        firstLevelParentType={firstLevelParentType}
        urlList={listUrl}
        urlSecondList={listUrlSecond}
        urlThirdList={listUrlThird}
      />
      <DataDisplayBox>
        <Box>
          <LanguageSelect value={idLanguage} setValue={setIdLanguage} />
        </Box>
        <Grid>
          <DetailGrid object={object} fields={VideoDto.getDetailFields()} />
        </Grid>
      </DataDisplayBox>
    </React.Fragment>
  );
};

export { ThirdLevelSpecialVideoDetail };
