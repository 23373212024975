// import RequestListDTO from "dto/app/requestlist.dto";
// import SignInDto from "dto/auth/signin.dto";
// import SignInHashDto from "dto/auth/signinhash.dto";

// import Idto from "interfaces/idto.interface";
// import IRepository from "interfaces/irepository.interface";
// import GeneralRepository from "repositories/general.repository";

// export default class UserRepository extends GeneralRepository implements IRepository {
//   async signIn(data: SignInDto, cb?: any, cbparameters?: any): Promise<any> {
//     return await this.postAxios(
//       `${REACT_APP_API_SERVER}/auth/signin`,
//       cb,
//       data,
//       cbparameters
//     );
//   }

//   async signInHash(
//     data: SignInHashDto,
//     cb?: any,
//     cbparameters?: any
//   ): Promise<any> {
//     return await this.postAxios(
//       `${REACT_APP_API_SERVER}/auth/signin-hash`,
//       cb,
//       data,
//       cbparameters
//     );
//   }

//   async generateToken(cb?: any, cbparameters?: any): Promise<any> {
//     return await this.getAxios(
//       generateTokenUrl,
//       cb,
//       {},
//       cbparameters
//     );
//   }

//   async refreshToken(cb?: any, cbparameters?: any): Promise<any> {
//     return await this.getAxios(
//       urlRefreshToken,
//       cb,
//       {},
//       cbparameters
//     );
//   }

//   async get(id: string, cb?: any, cbparameters?: any): Promise<any> {
//     const url = (REACT_APP_API_SERVER ?? '') + (REACT_APP_URLPREFIX ?? '') + "/user/" + id;
//     return await this._get(url, cb, cbparameters);
//   }

//   async getList(
//     cb?: any,
//     cbparameters?: any,
//     data?: RequestListDTO
//   ): Promise<any> {
//     const url = (REACT_APP_API_SERVER ?? '') + (REACT_APP_URLPREFIX ?? '') + "/user";

//     return await this._getList(url, cb, cbparameters, data);
//   }

//   async add(cb?: any, cbparameters?: any, data?: Idto): Promise<any> {
//     const url = (REACT_APP_API_SERVER ?? '') + (REACT_APP_URLPREFIX ?? '') + "/user";

//     return await this.postAxios(url, cb, data, cbparameters);
//   }

//   async addNewStudent(cb?: any, cbparameters?: any, data?: Idto): Promise<any> {
//     const url = (REACT_APP_API_SERVER ?? '') + (REACT_APP_URLPREFIX ?? '') + "/user/new-student";

//     return await this.postAxios(url, cb, data, cbparameters);
//   }

//   async delete(id: string, cb?: any, cbparameters?: any): Promise<any> {
//     const url = (REACT_APP_API_SERVER ?? '') + (REACT_APP_URLPREFIX ?? '') + "/user/" + id;
//     return await this.deleteAxios(url, cb, cbparameters);
//   }

//   async update(id: string, cb?: any, data?: Idto, cbparameters?: any): Promise<any> {
//     const url = (REACT_APP_API_SERVER ?? '') + (REACT_APP_URLPREFIX ?? '') + "/user/" + id;

//     return await this.putAxios(url, cb, data, cbparameters);
//   }

//   async patch(id: string, cb?: any, data?: Idto, cbparameters?: any): Promise<any> {
//     const url = (REACT_APP_API_SERVER ?? '') + (REACT_APP_URLPREFIX ?? '') + "/user/" + id;

//     return await this.patchAxios(url, cb, data, cbparameters);
//   }
//   async modifyPasswordByAdmin(id: string, cb?: any, data?: Idto, cbparameters?: any): Promise<any> {
//     const url = (REACT_APP_API_SERVER ?? '') + (REACT_APP_URLPREFIX ?? '') + "/user/password/" + id;

//     return await this.patchAxios(url, cb, data, cbparameters);
//   }

//   async modifyPassword(cb?: any, cbparameters?: any, data?: Idto): Promise<any> {
//     const url = REACT_APP_API_SERVER + "/user/modify-password";

//     return await this.postAxios(url, cb, data, cbparameters);
//   }

// }

import SignInDto from "dto/auth/signin.dto";
import SignInHashDto from "dto/auth/signinhash.dto";
import Idto from "interfaces/idto.interface";
import IRepository from "interfaces/irepository.interface";
import {GlobalGeneralRepository} from "repositories/globalgeneral.repository";

const { REACT_APP_API_SERVER } = process.env;
export const urlRefreshToken = `${REACT_APP_API_SERVER}/auth/refresh`;
export const generateTokenUrl = `${REACT_APP_API_SERVER}/auth/generate`;

export class UserRepository
  extends GlobalGeneralRepository
  implements IRepository
{
  constructor() {
    super("user");
  }

  async signIn(data: SignInDto, cb?: any, cbparameters?: any): Promise<any> {
    return await this.postAxios(
      `${REACT_APP_API_SERVER}/auth/signin`,
      cb,
      data,
      cbparameters
    );
  }

  async signInHash(
    data: SignInHashDto,
    cb?: any,
    cbparameters?: any
  ): Promise<any> {
    return await this.postAxios(
      `${REACT_APP_API_SERVER}/auth/signin-hash`,
      cb,
      data,
      cbparameters
    );
  }

  async modifyPassword(
    cb?: any,
    cbparameters?: any,
    data?: Idto
  ): Promise<any> {
    const url = `${REACT_APP_API_SERVER}/user/modify-password`;

    return await this.postAxios(url, cb, data, cbparameters);
  }
  async modifyPasswordByAdmin(
    id: string,
    cb?: any,
    data?: Idto,
    cbparameters?: any
  ): Promise<any> {
    const url = `${this.mainUrl}/password/${id}`;

    return await this.patchAxios(url, cb, data, cbparameters);
  }

  async patch(
    id: string,
    cb?: any,
    data?: Idto,
    cbparameters?: any
  ): Promise<any> {
    const url = `${this.mainUrl}/${id}`;
    return await this.patchAxios(url, cb, data, cbparameters);
  }

  async addNewStudent(cb?: any, cbparameters?: any, data?: Idto): Promise<any> {
    const url = `${this.mainUrl}/new-student`;

    return await this.postAxios(url, cb, data, cbparameters);
  }

  async generateToken(cb?: any, cbparameters?: any): Promise<any> {
    return await this._get(generateTokenUrl, cb, cbparameters);
  }

  async refreshToken(cb?: any, cbparameters?: any): Promise<any> {
    return await this._get(urlRefreshToken, cb, cbparameters);
  }
  logout = async (cb?: any, cbparameters?: any): Promise<any> => {
    return await this.getAxios(
      `${REACT_APP_API_SERVER}/auth/logout`,
      cb,
      {},
      cbparameters
    );
  };
}
