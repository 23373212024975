import { useEffect, useMemo, useState } from "react";
import { useResource } from "./useResource";
import { CommonTools } from "tools/utils/commontools";

type Props = (
  mainObject: string,
  currentRoute: any
) => {
  listUrlSecond: string;
};

const useGetSecondUrls: Props = (mainObject, currentRoute) => {
  const [listUrlSecond, setListUrlSecond] = useState("");

  const { _getListSecondUrl } = useResource();

  useEffect(() => {
    if (!mainObject || !currentRoute) return;
    const mu = CommonTools.generateMainUrl(mainObject);

    const lu = _getListSecondUrl(mu);
    setListUrlSecond(lu);
  }, [mainObject, currentRoute]);

  return useMemo(
    () => ({
      listUrlSecond,
    }),
    [listUrlSecond]
  );
};

export { useGetSecondUrls };
