import GeneralService from "services/globalgeneral.service";
import IService from "interfaces/iservice.interface";

import { SmsTemplateRepository } from "repositories/notification/smstemplate.repository";

export class SmsTemplateService
  extends GeneralService<SmsTemplateRepository>
  implements IService
{
  constructor() {
    super(
      new SmsTemplateRepository(),
      [
        { key: "status", type: "status" },
      ],
      "identifier"
    );
  }
}
