import GeneralService from "services/globalgeneral.service";
import IService from "interfaces/iservice.interface";

import { AppNotifyTemplateRepository } from "repositories/notification/appnotifytemplate.repository";

export class AppNotifyTemplateService
  extends GeneralService<AppNotifyTemplateRepository>
  implements IService
{
  constructor() {
    super(
      new AppNotifyTemplateRepository(),
      [
        { key: "status", type: "status" },
        { key: "destinationtype", type: "types" },
      ],
      "identifier"
    );
  }
}
