import { Box } from "@mui/material";
import { StatusSelect } from "components/elements/select/StatusSelect";
import { EmailTemplateDto } from "dto/notification/emailtemplate.dto";
import { useForm } from "hooks/useForm";

import React from "react";

import RequiredValidator from "validators/required.validator";
import { SelectFromEmail } from "components/notification/fromemail/SelectFromEmail";
import { useResource } from "hooks/useResource";
import MyTextField from "components/elements/form/mytextfield";
import { LanguageSelect } from "components/static/language/newLanguageSelect";
import { MyCKEditorWithParams } from "components/elements/ckeditor/MyCKEditorWithParams";
import { MyButton } from "components/elements/button/MyButton";


type Props = {
  defaultObject: EmailTemplateDto;
  onSubmit: (data: EmailTemplateDto) => void;
  loading: boolean;
  forAdd?: boolean;
};
const FormEmailTemplate: React.FC<Props> = ({
  defaultObject,
  onSubmit,
  loading,
  forAdd = false,
}) => {
  const { LL } = useResource();

  const [obj, disabled, setObjField, ,_vresults,setLanguage] = useForm<EmailTemplateDto>(
    defaultObject,
    RequiredValidator.getValidators([
      "identifier",
      "idfromemail",
      "message",
    ])
  );

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onSubmit(obj);
  };
  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Box>
          <MyTextField
            field="identifier"
            _label={LL("field_identifier")}
            setObj={setObjField}
            value={obj.identifier}
            _vresults={_vresults}
          />
        </Box>
        <Box mt={3}>
          <SelectFromEmail
            setObjectField={setObjField}
            value={obj.idfromemail}
          />
        </Box>
        {forAdd && (
          <Box mt={3}>
            <LanguageSelect value={obj.idlanguage ?? ""} setValue={setLanguage} />
          </Box>
        )}
        <Box mt={3}>
          <StatusSelect setObjField={setObjField} value={obj.status} />
        </Box>
        <Box mt={3}>
          <MyTextField
            field="toemail"
            _label={LL("toemail")}
            setObj={setObjField}
            value={obj.toemail}
            _vresults={_vresults}
          />
        </Box>
        <Box mt={3}>
          <MyTextField
            field="replyto"
            _label={LL("replyto")}
            setObj={setObjField}
            value={obj.replyto}
            _vresults={_vresults}
          />
        </Box>
        <Box mt={3}>
          <MyTextField
            field="cc"
            _label={LL("cc")}
            setObj={setObjField}
            value={obj.cc}
            _vresults={_vresults}
          />
        </Box>
        <Box mt={3}>
          <MyTextField
            field="bcc"
            _label={LL("bcc")}
            setObj={setObjField}
            value={obj.bcc}
            _vresults={_vresults}
          />
        </Box>
        <Box mt={3}>
          <MyTextField
            field="fromname"
            _label={LL("fromname")}
            setObj={setObjField}
            value={obj.fromname}
            _vresults={_vresults}
          />
        </Box>
        <Box mt={3}>
          <MyTextField
            field="toname"
            _label={LL("toname")}
            setObj={setObjField}
            value={obj.toname}
            _vresults={_vresults}
          />
        </Box>
        <Box mt={3}>
          <MyTextField
            field="subject"
            _label={LL("subject")}
            setObj={setObjField}
            value={obj.subject}
            multiline
            minRows={3}
            _vresults={_vresults}
          />
        </Box>
        <Box mt={3}>
          <MyCKEditorWithParams
            field="message"
            setObjectField={setObjField}
            data={obj.message}
            params={obj.parameters ?? []}
          />
        </Box>
        <Box mt={3}>
        <MyButton
            type="submit"
            disabled={loading || disabled}
            color="primary"
            fullWidth
            variant="contained"
            size="large"
          >
            {LL("Bt_Submit")}
          </MyButton>
        </Box>
      </form>
    </Box>
  );
};

export { FormEmailTemplate };
