import { Box } from "@mui/material";
import { StatusSelect } from "components/elements/select/StatusSelect";
import { SmsTemplateDto } from "dto/notification/smstemplate.dto";
import { useForm } from "hooks/useForm";

import React from "react";

import RequiredValidator from "validators/required.validator";

import { useResource } from "hooks/useResource";
import MyTextField from "components/elements/form/mytextfield";
import { LanguageSelect } from "components/static/language/newLanguageSelect";
import { MyCKEditorWithParams } from "components/elements/ckeditor/MyCKEditorWithParams";
import { MyButton } from "components/elements/button/MyButton";


type Props = {
  defaultObject: SmsTemplateDto;
  onSubmit: (data: SmsTemplateDto) => void;
  loading: boolean;
  forAdd?: boolean;
};
const FormSmsTemplate: React.FC<Props> = ({
  defaultObject,
  onSubmit,
  loading,
  forAdd = false,
}) => {
  const { LL } = useResource();

  const [obj, disabled, setObjField, , _vresults, setLanguage] =
    useForm<SmsTemplateDto>(
      defaultObject,
      RequiredValidator.getValidators(["identifier", "message"])
    );

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onSubmit(obj);
  };
  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Box>
          <MyTextField
            field="identifier"
            _label={LL("field_identifier")}
            setObj={setObjField}
            value={obj.identifier}
            _vresults={_vresults}
          />
        </Box>

        {forAdd && (
          <Box mt={3}>
            <LanguageSelect
              value={obj.idlanguage ?? ""}
              setValue={setLanguage}
            />
          </Box>
        )}
        <Box mt={3}>
          <StatusSelect setObjField={setObjField} value={obj.status} />
        </Box>
        <Box mt={3}>
          <MyTextField
            field="fromname"
            _label={LL("fromname")}
            setObj={setObjField}
            value={obj.fromname}
            _vresults={_vresults}
          />
        </Box>
        
        <Box mt={3}>
          <MyTextField
            field="tonumber"
            _label={LL("tonumber")}
            setObj={setObjField}
            value={obj.tonumber}
            _vresults={_vresults}
          />
        </Box>

        <Box mt={3}>
          <MyCKEditorWithParams
            field="sms"
            setObjectField={setObjField}
            data={obj.sms}
            params={obj.parameters ?? []}
          />
        </Box>
        <Box mt={3}>
          <MyButton
            type="submit"
            disabled={loading || disabled}
            color="primary"
            fullWidth
            variant="contained"
            size="large"
          >
            {LL("Bt_Submit")}
          </MyButton>
        </Box>
      </form>
    </Box>
  );
};

export { FormSmsTemplate };
