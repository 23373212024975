import { MyButton } from "components/elements/button/MyButton";
import { HeaderList } from "components/elements/list/HeaderList";
import MyTable from "components/elements/tabeltools/MyTable";
import RequestListDTO from "dto/app/requestlist.dto";
import { LabelDto } from "dto/static/label.dto";
import { useListAdmin } from "hooks/useListAdmin";
import { useResource } from "hooks/useResource";
import { IPage } from "interfaces/page.interface";
import React, { useState } from "react";
import { LabelService } from "services/static/label.service";
import { CommonTools } from "tools/utils/commontools";
import { GridMode } from "./GridMode";
import { useCookies } from "react-cookie";
import DateTools from "tools/utils/date.tools";
import { Config } from "tools/utils/config";

const service = new LabelService();

const GridModeBoundary: React.FC<IPage> = ({ currentRoute, mainObject }) => {
  const [cookies, setCookie] = useCookies();
  const setCookieMode = (value: boolean) => {
    if (value) {
      setCookie("grid_mode", value, {
        path: "/",

        expires: DateTools.getDate(Config.COOKIE_EXPIRES),
      });
    }
  };

  const getCookieGridMode = () => {
    const value = cookies["grid_mode"] ?? false;
    return value;
  };
  const [gridMode, setGridMode] = useState<boolean>(getCookieGridMode());

  const { LL } = useResource();
  const mainUrl = CommonTools.generateMainUrl(mainObject);

  const addUrl = CommonTools.generateAddUrl(mainObject);
  const onClick = () => {
    setGridMode(!gridMode);
    setCookieMode(!gridMode);
  };
  if (!mainObject) return null;
  return (
    <React.Fragment>
      <HeaderList
        mainObject={mainObject}
        mainUrl={mainUrl}
        addUrl={addUrl}
        showAddButton={false}
      >
        <MyButton cb={onClick} color="primary">
          {gridMode ? LL("bt_grid_table") : LL("bt_grid_mode")}
        </MyButton>
      </HeaderList>
      {gridMode ? (
        <GridMode currentRoute={currentRoute} mainObject={mainObject} />
      ) : (
        <TableMode currentRoute={currentRoute} mainObject={mainObject} />
      )}
    </React.Fragment>
  );
};

const TableMode: React.FC<IPage> = ({ currentRoute, mainObject }) => {
  const getListFun = (cb: any, cbParams: any, reqList: any) => {
    service.getList(cb, cbParams, reqList);
  };
  const getRequestListFun = (req: RequestListDTO) => {
    return service.getDefaultRequestList(req);
  };
  const deleteFun = (id: string, cb?: any, params?: any) => {
    service.delete(id, cb, params);
  };
  const {
    setDeleteOneObject,
    handleDelete,
    rows,
    total,
    totalPage,
    deleteOneObject,
    reqList,
    setReqList,
    loading,
  } = useListAdmin<LabelDto>(
    mainObject,
    currentRoute,
    getListFun,
    getRequestListFun,
    deleteFun
  );
  const { LL } = useResource();
  if (!currentRoute) return null;
  if (!mainObject) return null;
  if (!reqList) return null;
  return (
    <MyTable
      _columns={LabelDto.getColumns(LL, mainObject, setDeleteOneObject)}
      _data={rows}
      _total={total}
      _totalPage={totalPage}
      _reqList={reqList}
      _tbFilterList={LabelDto.getFilters()}
      setReqList={setReqList}
      loading={loading}
      currentRoute={currentRoute}
      onDelete={handleDelete}
      mainObject={mainObject}
      fieldToShowOnDelete="identifier"
      deleteOneObject={deleteOneObject}
      setDeleteOneObject={setDeleteOneObject}
    />
  );
};

export { GridModeBoundary };
