import React from "react";
import { GeneralFilterSelect } from "./GeneralFilterSelect";
import SelectOptions from "dto/app/selectoptions.dto";

type Props = {
  onPage: number;
  setOnPage: (onPage: number) => void;
  values: number[];
};

const OnPageSelect: React.FC<Props> = ({ onPage, setOnPage, values }) => {
  const options: SelectOptions[] = values.map((item) => {
    return { value: item, label: item.toString() };
  });

  if (!values || !values.length ) return null;
  return (
    <GeneralFilterSelect
      options={options}
      value={onPage}
      setValue={setOnPage}
      _label="onPage"
      size="small"
    />
  );
};

export { OnPageSelect };
