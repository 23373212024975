import React from "react";

import { IPage } from "interfaces/page.interface";
import { GalleryService } from "services/system/gallery.service";
import { GalleryDto } from "dto/system/gallery.dto";
import { LanguageSelect } from "components/static/language/newLanguageSelect";
import { CommonTools } from "tools/utils/commontools";
import { ComponentCommonTools } from "tools/utils/componentcommontools";
import { FormGallery } from "components/special/gallery/FormGallery";
import { DataDisplayBox } from "components/elements/display/DataDisplayBox";
import { Box } from "@mui/material";
import { useResource } from "hooks/useResource";
import { Loading } from "components/elements/loading/Loading";
import { useEditSpecialThirdLevel } from "hooks/useEditSpecialThirdLevel";
import { HeaderEditSpecialThirdLevel } from "components/elements/edit/HeaderEditSpecialThirdLevel";

const service = new GalleryService();
const ThirdLevelSpecialGalleryEdit: React.FC<IPage> = ({
  currentRoute,
  mainObject,
}) => {
  const get = (id: string, cb: any, cbParams: any, idLanguage?: string) => {
    service.get(id, cb, cbParams, idLanguage);
  };
  const { getCookieStayOnPage } = useResource();
  const typeCallback = getCookieStayOnPage(mainObject || "");

  const {
    mainUrl,
    detailUrl,
    object,
    loading,
    setLoading,
    id,
    idLanguage,
    setIdLanguage,
    parentId,
    parentType,
    getByLanguage,
    firstLevelParentId,
    firstLevelParentType,
    listUrl,
    listUrlThird,
    listUrlSecond
  } = useEditSpecialThirdLevel<GalleryDto>(
    "thirdlevelgallery",
    mainObject,
    currentRoute,
    get,
    true
  );

  const onSubmit = (obj: GalleryDto) => {
    if (!obj) return;
    setLoading(true);
    obj.idlanguage = idLanguage;
    obj.idparent = parentId;
    obj.parent = parentType;

    const callBackFunction = typeCallback
      ? getByLanguage
      : ComponentCommonTools.handleSuccessAddAndEditSpecialThirdLevel;
    const callBackParams = typeCallback
      ? {}
      : {
          mainObject,
          parentType,
          parentId,
          specialPart: "thirdlevelgallery",
          cb: CommonTools.processLoadingCallback(setLoading),
          idLanguage,
          firstLevelParentId,
          firstLevelParentType,
        };
    service.update(id, callBackFunction, callBackParams, obj);
  };
  if (!currentRoute) return null;
  if (!mainObject) return null;
  if (loading) return <Loading />;
  if (!object) return null;

  return (
    <React.Fragment>
      <HeaderEditSpecialThirdLevel
        mainObject={mainObject}
        mainUrl={mainUrl}
        detailUrl={detailUrl}
        object={object}
        idLanguage={idLanguage}
        parentId={parentId}
        parentType={parentType}
        specialType="thirdlevelgallery"
        firstLevelParentId={firstLevelParentId}
        firstLevelParentType={firstLevelParentType}
        listUrl={listUrl}
        listSecondUrl={listUrlSecond}
        listThirdUrl={listUrlThird}
      />
      <DataDisplayBox>
        <Box>
          <LanguageSelect value={idLanguage} setValue={setIdLanguage} />
        </Box>

        <Box mt={3}>
          <FormGallery
            defaultObject={GalleryDto.parseForEdit(object)}
            onSubmit={onSubmit}
            loading={loading}
          />
        </Box>
      </DataDisplayBox>
    </React.Fragment>
  );
};

export { ThirdLevelSpecialGalleryEdit };
