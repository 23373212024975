import GeneralService from "services/globalgeneral.service";
import IService from "interfaces/iservice.interface";

import {NotificationRepository} from "repositories/notification/notification.repository";

import RequestListDTO from "dto/app/requestlist.dto";

export  class NotificationService
  extends GeneralService<NotificationRepository>
  implements IService
{
  constructor() {
    super(
      new NotificationRepository(),
      [
        { key: "status", type: "status" },
        { key: "date", type: "timestamp" },
        { key: "showdate", type: "timestamp" },
        { key: "showstarttime", type: "timestamp" },
        { key: "showendtime", type: "timestamp" },
        { key: "type", type: "types" },
        { key: "typerecipient", type: "types" },
      ],
      "date"
    );
  }
  getDefaultRequestListSelect(onPage?: number): RequestListDTO {
    onPage = onPage ?? Number(process.env.REACT_APP_ONPAGE);
    if (onPage === 0) onPage = Number(process.env.REACT_APP_ONPAGE);
    var defaultRequestList = new RequestListDTO();
    defaultRequestList.page = Number(process.env.REACT_APP_PAGE);
    defaultRequestList.onpage = onPage;
    return defaultRequestList;
}
}
// import RequestListDTO from "dto/app/requestlist.dto";
// import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
// import ResultObjectDTO from "dto/app/resultobject.dto";
// import { NotificationDto } from "dto/user/notification.dto";
// import ResultListDTO from "dto/app/resultlist.dto";
// import { logger } from "tools/utils/logger";
// import IService from "interfaces/iservice.interface";
// import NotificationRepository from "repositories/user/notification.repository";
// import { Status } from "tools/types/status";
// import GeneralService from "services/general.service";

// export default class NotificationService extends GeneralService implements IService {
//     notificationRepository: NotificationRepository = new NotificationRepository();
//     constructor() {
//         super();
//         this.handleGetList = this.handleGetList.bind(this);
//         this.handleGet = this.handleGet.bind(this);
//     }
//     handleUpdate(result?: any, cbparameters?: any, data?: any): any {
//         let rez = new ResultObjectDTO();
//         rez = result;

//         if (result.err) return;

//         if (cbparameters && cbparameters._cb) {
//             cbparameters._cb(rez, cbparameters, data);
//         }
//     }

//     async update(id: string, cb?: any, cbparameters?: any, data?: any): Promise<any> {

//         cbparameters = cbparameters ? cbparameters : {};
//         cbparameters._cb = cb;
//         this.notificationRepository.update(id, this.handleUpdate, data, cbparameters);
//     }

//     handleDelete(result?: any, cbparameters?: any): any {
//         let rez = new ResultObjectDTO();
//         rez = result;
//         if (result.err) return;

//         if (cbparameters && cbparameters._cb) {
//             cbparameters._cb(rez, cbparameters);
//         }
//     }

//     async delete(id: string, cb?: any, cbparameters?: any): Promise<any> {
//         cbparameters = cbparameters ? cbparameters : {};
//         cbparameters._cb = cb;

//         this.notificationRepository.delete(id, this.handleDelete, cbparameters);
//     }

//     handleGet(result?: any, cbparameters?: any, data?: any): any {
//         let rez = new ResultObjectDTO();
//         rez = result;
//         if (result.err) return;
//         this.populateObject(rez.obj, result.obj, [
// { key: 'status', type: 'status' },
// { key: 'date', type: 'timestamp' },
// { key: 'showdate', type: 'timestamp' },
// { key: 'showstarttime', type: 'timestamp' },
// { key: 'showendtime', type: 'timestamp' },
// { key: 'type', type: 'types' },
// { key: 'typerecipient', type: 'types' },
//         ]);
//         if (cbparameters && cbparameters._cb) {
//             cbparameters._cb(rez, cbparameters, data);
//         }

//         return rez;
//     }

//     async get(id: string, cb?: any, cbparameters?: any): Promise<any> {
//         cbparameters = cbparameters ? cbparameters : {};
//         cbparameters._cb = cb;
//         const t = await this.notificationRepository.get(id, this.handleGet, cbparameters);
//         if (cb == undefined) {
//             return this.handleGet(t);
//         }
//     }

//     handleGetList(result?: any, cbparameters?: any, data?: any): any {
//         let rez = new ResultListDTO();

//         rez = result;
//         if (result.err) return;

//         this.parseResultPopulate(result, rez, [
//             { key: 'status', type: 'status' },
//             { key: 'date', type: 'timestamp' },
//             { key: 'showdate', type: 'timestamp' },
//             { key: 'showstarttime', type: 'timestamp' },
//             { key: 'showendtime', type: 'timestamp' },
//             { key: 'type', type: 'types' },
//             { key: 'typerecipient', type: 'types' },

//         ]);

//         if (cbparameters && cbparameters._cb) {
//             cbparameters._cb(rez, cbparameters, data);
//         }

//         return rez;
//     }

//     async getList(
//         cb?: any,
//         cbparameters?: any,
//         data?: RequestListDTO
//     ): Promise<any> {
//         cbparameters = cbparameters ? cbparameters : {};
//         cbparameters._cb = cb;
//         const t = await this.notificationRepository.getList(this.handleGetList, cbparameters, data);
//         if (cb == undefined) {
//             return this.handleGetList(t);
//         }
//     }

//     handleAdd(result?: any, cbparameters?: any, data?: any): any {
//         let rez = new ResultObjectDTO();
//         rez = result;
//         if (result.err) return;

//         if (cbparameters && cbparameters._cb) {
//             cbparameters._cb(rez, cbparameters, data);
//         }
//     }

//     async add(
//         cb?: any,
//         cbparameters?: any,
//         data?: any
//     ): Promise<any> {
//         cbparameters = cbparameters ? cbparameters : {};
//         cbparameters._cb = cb;

//         this.notificationRepository.add(this.handleGetList, cbparameters, data);
//     }

//     // getDefaultRequestList(onPage?: number): RequestListDTO {
//     //     onPage = onPage ?? Number(process.env.REACT_APP_ONPAGE);
//     //     if (onPage === 0) onPage = Number(process.env.REACT_APP_ONPAGE);
//     //     var defaultRequestList = new RequestListDTO();
//     //     defaultRequestList.page = Number(process.env.REACT_APP_PAGE);
//     //     defaultRequestList.onpage = onPage;
//     //     var defaultRequestSortCriteria = new RequestSortCriteriaDTO()
//     //     defaultRequestSortCriteria.asc = true;
//     //     defaultRequestSortCriteria.field = 'status';
//     //     defaultRequestList.sortcriteria = []
//     //     defaultRequestList.sortcriteria?.push(defaultRequestSortCriteria);
//     //     return defaultRequestList;
//     // }

    // getDefaultRequestListSelect(onPage?: number): RequestListDTO {
    //     onPage = onPage ?? Number(process.env.REACT_APP_ONPAGE);
    //     if (onPage === 0) onPage = Number(process.env.REACT_APP_ONPAGE);
    //     var defaultRequestList = new RequestListDTO();
    //     defaultRequestList.page = Number(process.env.REACT_APP_PAGE);
    //     defaultRequestList.onpage = onPage;
    //     // var defaultRequestSortCriteria = new RequestSortCriteriaDTO()
    //     // defaultRequestSortCriteria.asc = true;
    //     // defaultRequestSortCriteria.field = 'date';
    //     // defaultRequestList.sortcriteria = []
    //     // defaultRequestList.sortcriteria?.push(defaultRequestSortCriteria);
    //     return defaultRequestList;
    // }

//     getDefaultRequestList(obj: RequestListDTO): RequestListDTO {
//         if (
//             !obj.sortcriteria ||
//             !Array.isArray(obj.sortcriteria) ||
//             !obj.sortcriteria.length
//         ) {
//             var sobj = new RequestSortCriteriaDTO();
//             sobj.asc = true;
//             sobj.field = "date";

//             obj.sortcriteria = [sobj];
//         }

//         return obj;
//     }
// }
