
import React from "react";
import { IPage } from "interfaces/page.interface";
import { GridModeBoundary } from "components/static/label/GridModeBoundary";

const Label: React.FC<IPage> = ({ currentRoute, mainObject }) => {
  return (
    <GridModeBoundary currentRoute={currentRoute} mainObject={mainObject} />
  );
};
export { Label };
