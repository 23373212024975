import { Box } from "@mui/material";

import { StatusSelect } from "components/elements/select/StatusSelect";
import MyTextField from "components/elements/form/mytextfield";
import { MyButton } from "components/elements/button/MyButton";
import { LabelDto } from "dto/static/label.dto";

import { useForm } from "hooks/useForm";
import { useResource } from "hooks/useResource";

import RequiredValidator from "validators/required.validator";

type Props = {
  defaultObject: LabelDto;
  onSubmit: (data: LabelDto) => void;
  loading: boolean;
};
const FormLabel: React.FC<Props> = ({ defaultObject, onSubmit, loading }) => {
  const { LL } = useResource();

  const [obj, disabled, setObjField, , vresults] = useForm<LabelDto>(
    defaultObject,
    RequiredValidator.getValidators(["value"])
  );

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onSubmit(obj);
  };
  return (
    <Box>
      <form onSubmit={handleSubmit}>
        
        <Box mt={3}>
          <StatusSelect value={obj.status} setObjField={setObjField} />
        </Box>
        <Box mt={3}>
          <MyTextField
            tabIndex={0}
            fullWidth
            id="value"
            name="value"
            variant="outlined"
            color="primary"
            _label={LL("label_text_field_value")}
            size="small"
            value={obj.value}
            setObj={setObjField}
            _vresults={vresults}
          />
        </Box>

        <MyButton
          tabIndex={6}
          disabled={disabled || loading}
          color="primary"
          fullWidth
          variant="contained"
          size="large"
          type="submit"
          sx={{ mt: 3 }}
          children={LL("Bt_Submit")}
        />
      </form>
    </Box>
  );
};

export { FormLabel };
