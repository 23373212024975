import React, { createContext, useState, useEffect, useContext } from "react";

import { v4 as uuidv4 } from "uuid";
import GeneralRepository from "repositories/general.repository";
import { MessageItem } from "components/message/MessageItem";

import IProvider from "interfaces/provider.interface";
import MessageDto from "dto/app/message.dto";
import { Box } from "@mui/material";

export const MessageContext = createContext({
  RegisterMessage: (obj: MessageDto) => {}
});

export const MessageProvider: React.FC<IProvider> = ({ children }) => {
  const [messages, setMessages] = useState<MessageDto[]>([]);

  const RegisterMessage = (obj: MessageDto) => {
    if (!obj.code) return;
    obj.id = uuidv4();
    const _m = [...messages, obj];
    setMessages(_m);
  };

  const CloseMessage = (obj: MessageDto) => {
    let t = [];
    for (var i in messages) {
      if (messages[i].id == obj.id) continue;
      t.push(messages[i]);
    }
    setMessages(t);
  };

  // ----------------------------------------
  useEffect(() => {
    GeneralRepository.setMessagesF(RegisterMessage);
  }, []);

  // ----------------------------------------
  const processMessageList = () => {
    if (!messages) return null;
    if (!messages.length) return null;

    return (
      <Box>
        {messages.map((obj, i) => {
          return (
            <MessageItem _obj={obj} _closeMessage={CloseMessage} key={obj.id} />
          );
        })}
      </Box>
    );
  };

  const value = {
    RegisterMessage
  };

  return (
    <MessageContext.Provider value={value}>
      {children}

      {processMessageList()}
    </MessageContext.Provider>
  );
};

