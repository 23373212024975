import React from "react";

import { IPage } from "interfaces/page.interface";

import { DataDisplayBox } from "components/elements/display/DataDisplayBox";
import { Box, Grid } from "@mui/material";
import { HeaderEdit } from "components/elements/edit/HeaderEdit";

import { Loading } from "components/elements/loading/Loading";
import { useEditObject } from "hooks/useEditObject";

import { CommonTools } from "tools/utils/commontools";
import { ComponentCommonTools } from "tools/utils/componentcommontools";

import { FormCourseTeacherRequest } from "components/course/courseteacherrequest/FormCourseTeacherRequest";
import { CourseTeacherRequestDto } from "dto/course/courseteacherrequest.dto";
import { CourseTeacherRequestService } from "services/course/courseteacherrequest.service";
import { DetailGrid } from "components/elements/generalcomponents/DetailGrid";
import { useAuth } from "hooks/useAuth";

const service = new CourseTeacherRequestService();
const EditCourseTeacherRequest: React.FC<IPage> = ({
  currentRoute,
  mainObject,
}) => {
  const get = (id: string, cb: any, cbParams: any, idLanguage?: string) => {
    service.get(id, cb, cbParams, idLanguage);
  };

  const { mainUrl, detailUrl, object, loading, setLoading, id, listUrl } =
    useEditObject<CourseTeacherRequestDto>(mainObject, currentRoute, get);
  const { user } = useAuth();

  const handleAccept = (obj: CourseTeacherRequestDto) => {
    if (!obj) return;
    if (!user) return;
    if (!user.id) return;

    obj.idmanager = user.id;
    setLoading(true);
    service.accept(
      id,
      ComponentCommonTools.handleSuccessAddAndEdit,
      { mainObject, cb: CommonTools.processLoadingCallback(setLoading) },
      obj
    );
  };

  const handleReject = (obj: CourseTeacherRequestDto) => {
    if (!obj) return;
    if (!user) return;
    if (!user.id) return;

    obj.idmanager = user.id;
    setLoading(true);
    service.reject(
      id,
      ComponentCommonTools.handleSuccessAddAndEdit,
      { mainObject, cb: CommonTools.processLoadingCallback(setLoading) },
      obj
    );
  };

  if (!currentRoute) return null;
  if (!mainObject) return null;
  if (loading) return <Loading />;
  if (!object) return null;

  return (
    <React.Fragment>
      <HeaderEdit
        mainObject={mainObject}
        mainUrl={mainUrl}
        detailUrl={detailUrl}
        object={object}
        listUrl={listUrl}
      />
      <DataDisplayBox>
        <Grid>
          <DetailGrid
            object={object}
            fields={CommonTools.parseDetailFields(
              [
                "Type",
                "date",
                "Status",
                "teacherfullname",
                "teacher_email",
                "teachermessage",
                "course_name",
              ],
              CourseTeacherRequestDto.getDetailFields()
            )}
          />
        </Grid>
        <Box mt={3}>
          <FormCourseTeacherRequest
            defaultObject={CourseTeacherRequestDto.parseForEdit(object)}
            handleAccept={handleAccept}
            handleReject={handleReject}
            loading={loading}
          />
        </Box>
      </DataDisplayBox>
    </React.Fragment>
  );
};

export { EditCourseTeacherRequest };
