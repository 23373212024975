import { LabelTypes } from "tools/types/labeltypes";

export class Config {
  public static WS_URL = process.env.REACT_APP_WS_SERVER || "";
  public static API_URL = process.env.REACT_APP_API_SERVER || "";
  public static SITE_IDENTIFIER = process.env.REACT_APP_SITE_IDENTIFIER || "";
  public static ADMIN_SERVER_URL = process.env.REACT_APP_ADMIN_SERVER || "";
  public static WWW_SERVER_URL = process.env.REACT_APP_WWW_SERVER || "";
  public static SUPPORT_SERVER_URL = process.env.REACT_APP_SUPPORT_SERVER || "";

  public static ON_PAGE = Number(process.env.REACT_APP_ONPAGE) || 25;
  public static CLOSE_MESSAGE_SUCCESS =
    Number(process.env.REACT_APP_AUTOMATED_CLOSEMESSAGE_SUCCESS) || 10000;
  public static CLOSE_MESSAGE_WARNING =
    Number(process.env.REACT_APP_AUTOMATED_CLOSEMESSAGE_WARNING) || 7000;
  public static CLOSE_MESSAGE_ERROR =
    Number(process.env.REACT_APP_AUTOMATED_CLOSEMESSAGE_ERROR) || 5000;
  public static DRAWER_WIDTH = 300;
  public static TIME_BUFFER_JWT =
    Number(process.env.REACT_APP_TIME_BUFFER_JWT) || 600;
  public static URL_GENERATE_TOKEN = "/jwt/generate";

  public static DEFAULT_LANGUAGE =
    process.env.REACT_APP_DEFAULT_LANGUAGE_CODE || "en";

  public static PREFIX_APP = process.env.REACT_APP_PREFIX_APP || "adm";
  public static DEFAULT_TAB = "generalinfo";
  public static COOKIE_EXPIRES =
    Number(process.env.REACT_APP_USERCOOKIES_EXPIRE) || 100000;
  public static ID_TYPE_COURSE_LIVE =
    process.env.REACT_APP_ID_TYPE_COURSE_LIVE || "";
  public static ID_TYPE_COURSE_ONLINE =
    process.env.REACT_APP_ID_TYPE_COURSE_ONLINE || "";
  public static LABEL_TYPE =
    process.env.REACT_APP_LABEL_TYPE || LabelTypes.LABEL_ADMIN;
}
