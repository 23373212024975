// MyCKEditor.tsx

import React, { useState, useEffect, useRef } from "react";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import "ckeditor5/ckeditor5.css";
import {
  ClassicEditor,
  Bold,
  Essentials,
  Italic,
  Mention,
  Paragraph,
  Undo,
  SourceEditing,
  FontSize,
  Alignment,
  Link,
  Heading,
  FontBackgroundColor,
  FontColor,
  FontFamily,
  List,
  Indent,
  IndentBlock,
  MediaEmbed,
  SimpleUploadAdapter,
  Image,
  ImageCaption,
  ImageResize,
  ImageStyle,
  ImageToolbar,
  LinkImage,
  ImageInsert,
  ImageInsertViaUrl,
} from "ckeditor5";

interface MyCKEditorProps {
  field?: string | undefined;
  name?: string | undefined;
  data: string;
  setObj: (field: any, value: any) => void;
  onEditorReady?: (editor: any) => void;
}

const MyCKEditor: React.FC<MyCKEditorProps> = ({
  data,
  setObj,
  onEditorReady,
  ...props
}) => {
  const editorRef = useRef<any>(null);
  const field = props.field ? props.field : props.name;

  const handleChange = (event: any, editor: any) => {
    if (!setObj) return;
    if (!field) return;
    setObj(field, editor.getData());
  };

  useEffect(() => {
    if (editorRef.current && onEditorReady) {
      onEditorReady(editorRef.current);
    }
  }, [editorRef.current, onEditorReady]);

  return (
    <CKEditor
      data={data ?? ""}
      editor={ClassicEditor}
      onChange={handleChange}
      onReady={(editor) => {
        editorRef.current = editor;
        if (onEditorReady) {
          onEditorReady(editor);
        }
      }}
      config={{
        plugins: [
          Bold,
          Essentials,
          Italic,
          Mention,
          Paragraph,
          Undo,
          SourceEditing,
          FontSize,
          Alignment,
          Link,
          Heading,
          FontBackgroundColor,
          FontColor,
          FontFamily,
          List,
          Indent,
          IndentBlock,
          MediaEmbed,
          SimpleUploadAdapter,
          Image,
          ImageCaption,
          ImageResize,
          ImageStyle,
          ImageToolbar,
          LinkImage,
          ImageInsert,
          ImageInsertViaUrl,
        ],
        toolbar: {
          items: [
            "undo",
            "redo",
            "|",
            "heading",
            "sourceEditing",
            "|",
            "fontfamily",
            "fontsize",
            "fontColor",
            "fontBackgroundColor",
            "|",
            "bold",
            "italic",
            "alignment",
            "|",
            "link",
            "bulletedList",
            "numberedList",
            "|",
            "indent",
            "outdent",
            "|",
            "insertImage",
            "mediaEmbed",
          ],

          shouldNotGroupWhenFull: false,
        },

        image: {
          toolbar: [
            "imageStyle:block",
            "imageStyle:side",
            "|",
            "toggleImageCaption",
            "imageTextAlternative",
            "|",
            "linkImage",
          ],
          insert: {
            type: "auto",
            integrations: ["url"],
          },
        },

        mediaEmbed: {
          previewsInData: true,
        },
      }}
    />
  );
};

export default MyCKEditor;
