import GeneralService from "services/globalgeneral.service";
import IService from "interfaces/iservice.interface";

import { EmailTemplateRepository } from "repositories/notification/emailtemplate.repository";

export class EmailTemplateService
  extends GeneralService<EmailTemplateRepository>
  implements IService
{
  constructor() {
    super(
      new EmailTemplateRepository(),
      [{ key: "status", type: "status" }],
      "identifier"
    );
  }

  sendTestEmail = async (
    cb?: any,
    cbparameters?: any,
    data?: any
  ): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    const t = await this.repository.sendTestEmail(
      this.handleGet,
      cbparameters,
      data
    );

    if (cb === undefined) {
      return this.handleGet(t);
    }
  };
}
