import TbFilterDTO from "dto/app/tbfilter.dto";
import TbFilterListDTO from "dto/app/tbfilterlist.dto";
import { DetailObject } from "interfaces/detailobject.interface";
import Idto from "interfaces/idto.interface";
import { ComponentCommonTools } from "tools/utils/componentcommontools";
import FilterTools from "tools/utils/filtertools";
import { LabelTypes } from "tools/types/labeltypes";
import { Status } from "tools/types/status";
import { Types } from "tools/types/types";
import { LanguageDto } from "./language.dto";
import { CommonTools } from "tools/utils/commontools";

export class LabelDto implements Idto {
  id?: string;
  identifier?: string;
  type?: number;
  status?: number;
  value?: string;
  idlanguage?: string;
  language?: any;
  type_name?: any;
  status_name?: any;
  constructor(
    idlanguage?: string,
    type?: number,
    identifier?: string,
    value?: string,
    status?: number
  ) {
    this.status = Status.ACTIVE || status;
    this.type = type || LabelTypes.LABEL_ADMIN;
    this.value = value || "";
    this.identifier = identifier || "";
    this.idlanguage = idlanguage || "";
  }

  static getDetailFields(): Array<DetailObject> {
    return [
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Identifier",
        value: ["identifier"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Language",
        value: ["language", "name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Type",
        value: ["type_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Status",
        value: ["status_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Value",
        value: ["value"],
      },
    ];
  }

  static getColumns(
    LL: (str: string) => void,
    mainObj: string,
    deleteFun?: (obj: any) => void,
    cbParent?: any,
    specialType?: string
  ): Array<any> {
    return [
      ComponentCommonTools.columns_GetCheck(),
      ComponentCommonTools.columns_StandardColumn(LL, "identifier"),
      ComponentCommonTools.columns_ConstantColumn(LL),
      ComponentCommonTools.columns_ConstantColumn(LL, "type"),
      ComponentCommonTools.columns_StandardColumn(
        LL,
        "value",
        undefined,
        false
      ),
      ComponentCommonTools.columns_ActionsColumn(
        LL,
        mainObj,
        deleteFun,
        ["edit", "detail", "delete"],
        ["detail"],
        cbParent,
        specialType
      ),
    ];
  }

  static getFilters(): TbFilterListDTO {
    const obj = new TbFilterListDTO();

    let t: TbFilterDTO;

    t = new TbFilterDTO("searchvalue", FilterTools.fiterTypeText);
    obj.addFilter(t);

    t = new TbFilterDTO("status", FilterTools.fiterTypeSelectMultiple);
    t.setValues(Status.GA("clasificator", true));
    obj.addFilter(t);

    t = new TbFilterDTO("type", FilterTools.fiterTypeSelectMultiple);
    t.setValues(LabelTypes.GA("clasificator", true));
    obj.addFilter(t);

    return obj;
  }
}

export class UpdateLabelStatusDto implements Idto {
  id: string;
  identifier: string;
  status: number;

  constructor(id?: string, identifier?: string, status?: number) {
    this.id = id || "";
    this.status = status || Status.ACTIVE;
    this.identifier = identifier || "";
  }
}

export class GridLabelDto implements Idto {
  id: string;
  identifier: string;
  type: number;
  status: number;
  allvalues: GridLabelValueDto[];

  constructor(
    id?: string,
    identifier?: string,
    type?: number,
    status?: number,
    allvalues?: GridLabelValueDto[]
  ) {
    this.id = id || "";
    this.identifier = identifier || "";
    this.type = type || LabelTypes.LABEL_ADMIN;
    this.status = status || Status.ACTIVE;
    this.allvalues = allvalues || [];
  }

  static toLabelDto(label: GridLabelDto): UpdateLabelStatusDto {
    return new UpdateLabelStatusDto(
      CommonTools.processObjectField(label, ["id"]),
      CommonTools.processObjectField(label, ["identifier"]),
      parseInt(CommonTools.processObjectField(label, ["status"]))
    );
  }
}

export class GridLabelValueDto implements Idto {
  value: string;
  idlanguage: string;

  constructor(value?: string, idlanguage?: string) {
    this.value = value || "";
    this.idlanguage = idlanguage || "";
  }

  static prepareForm(
    langs: LanguageDto[],
    values: GridLabelValueDto[]
  ): GridLabelValueDto[] {
    const valueMap = new Map<string, GridLabelValueDto>(
      values.map((value) => [
        CommonTools.processObjectField(value, ["idlanguage"]),
        value,
      ])
    );

    return langs.map((lang) => {
      const existingValue = valueMap.get(
        CommonTools.processObjectField(lang, ["id"])
      );
      if (existingValue) {
        return existingValue;
      } else {
        return new GridLabelValueDto(
          "",
          CommonTools.processObjectField(lang, ["id"])
        );
      }
    });
  }

  static toLabelDto(label: GridLabelDto, value: GridLabelValueDto): LabelDto {
    const obj = new LabelDto(
      CommonTools.processObjectField(value, ["idlanguage"]),
      parseInt(CommonTools.processObjectField(label, ["type"])),
      CommonTools.processObjectField(label, ["identifier"]),
      CommonTools.processObjectField(value, ["value"])
    );
    obj.id = CommonTools.processObjectField(label, ["id"]);
    obj.status = parseInt(CommonTools.processObjectField(label, ["status"]));
    return obj;
  }
}
