import GeneralService from "services/globalgeneral.service";
import IService from "interfaces/iservice.interface";

import { SiteNotifyTemplateRepository } from "repositories/notification/sitenotifytemplate.repository";

export class SiteNotifyTemplateService
  extends GeneralService<SiteNotifyTemplateRepository>
  implements IService
{
  constructor() {
    super(
      new SiteNotifyTemplateRepository(),
      [
        { key: "status", type: "status" },
        { key: "destinationtype", type: "types" },
      ],
      "identifier"
    );
  }
}
