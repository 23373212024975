import IRepository from "interfaces/irepository.interface";
import { GlobalGeneralRepository } from "repositories/globalgeneral.repository";

export class SiteNotifyTemplateRepository
  extends GlobalGeneralRepository
  implements IRepository
{
  constructor() {
    super("sitenotifytemplate");
  }
}
