import { Grid } from "@mui/material";

import { TestDto } from "dto/course/test.dto";
import { IPage } from "interfaces/page.interface";
import React, { useState } from "react";
import { TestService } from "services/course/test.service";

import { DataDisplayBox } from "components/elements/display/DataDisplayBox";

import { HeaderDetailSpecial } from "components/elements/detail/HeaderDetailSpecial";
import { Loading } from "components/elements/loading/Loading";
import { DetailGrid } from "components/elements/generalcomponents/DetailGrid";
import { useDetailSpecial } from "hooks/useDetailSpecial";
import { MyButton } from "components/elements/button/MyButton";
import { TestDialog } from "components/course/test/TestDialog";
import { useResource } from "hooks/useResource";

const service = new TestService();
const SpecialTestDetail: React.FC<IPage> = ({ currentRoute, mainObject }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const get = (id: string, cb: any, cbParams: any) => {
    service.get(id, cb, cbParams);
  };
  const { LL } = useResource();
  const {
    mainUrl,
    editUrl,
    parentType,
    parentId,
    object,
    loading,
    mainListUrl,
    setObject,
    listUrlSecond
  } = useDetailSpecial<TestDto>("test", currentRoute, get);

  if (!currentRoute) return null;
  if (!mainObject) return null;
  if (loading) return <Loading />;
  if (!object) return null;

  return (
    <React.Fragment>
      <HeaderDetailSpecial
        object={object}
        mainObject={mainObject}
        mainUrl={mainUrl}
        parentId={parentId}
        parentType={parentType}
        editUrl={editUrl}
        specialType="test"
        mainListUrl={mainListUrl}
        urlListSecond={listUrlSecond}
      >
        <MyButton cb={handleOpen}>{LL("questions")}</MyButton>
      </HeaderDetailSpecial>
      <DataDisplayBox>
        <Grid>
          <DetailGrid object={object} fields={TestDto.getDetailFields()} />
        </Grid>

        <TestDialog
          object={object}
          open={open}
          handleClose={handleClose}
          setObject={setObject}
        />
      </DataDisplayBox>
    </React.Fragment>
  );
};

export { SpecialTestDetail };
