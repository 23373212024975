import { Box } from "@mui/material";
import React from "react";

type Props = {
  children: React.ReactNode;
};

const DataDisplayBox: React.FC<Props> = ({ children }) => {
  return (
    <Box
      sx={{
        bgcolor: "background.paper",
        boxShadow: 2,
        p: 4,
        maxWidth: { xs: "100%", lg: "80%", xl: "60%" },
      }}>
      {children}
    </Box>
  );
};

export { DataDisplayBox };
