import { LabelTypes } from "tools/types/labeltypes";
import SelectOptions from "dto/app/selectoptions.dto";

export class Types {
  static _LL: any = false;

  static setLL(_l: any): any {
    this._LL = _l;
  }

  // -------------------------------------

  public static ADMIN = 1;
  public static SITE = 2;

  public static SECTION_TOP = 11;
  public static SECTION_LEFT = 12;
  public static SECTION_RIGHT = 13;
  public static SECTION_BOTTOM = 14;
  public static SECTION_INFO = 15;
  public static SECTION_SUPPORT_FOOTER = 16;
  public static SECTION_SUPPORT_SECOND_FOOTER = 17;

  public static FAQ_GENERAL = 21;

  public static BLOG_GENERAL = 31;

  public static QUESTION_GENERAL = 41;

  public static TYPE_OWNER_STUDENT = 51;

  public static TYPE_TRANSACTION_BASIC = 61;

  public static TRUE_ANSWER = 71;
  public static FALSE_ANSWER = 72;

  public static SECTION_TOP_ADVERTISEMENT = 811;
  public static SECTION_INFO_ADVERTISEMENT = 822;
  public static SECTION_BOTTOM_ADVERTISEMENT = 833;
  public static SECTION_HOME_1 = 834;
  public static SECTION_HOME_2 = 835;
  public static SECTION_HOME_3 = 836;
  public static SECTION_BENEFITS = 837;
  public static SECTION_SUGGESTIONS = 838;
  public static SECTION_BECOME_TEACHER_1 = 839;
  public static SECTION_BECOME_TEACHER_2 = 840;
  public static SECTION_BECOME_TEACHER_3 = 841;
  public static SECTION_BECOME_TEACHER_4 = 842;
  public static SECTION_BECOME_TEACHER_5 = 843;
  public static SECTION_CREATE_NEW_COURSE = 844;
  // -------------------------------------
  public static ADVERTISEMENT_TYPE_SEARCH = 91;
  public static ADVERTISEMENT_TYPE_MEDIUM_BANNER = 92;
  public static ADVERTISEMENT_TYPE_SMALL_BANNER = 93;
  public static ADVERTISEMENT_TYPE_BANNER = 94;
  public static ADVERTISEMENT_TYPE_BANNER_IMAGE_LEFT = 95;
  public static ADVERTISEMENT_TYPE_BANNER_IMAGE_RIGHT = 96;
  public static ADVERTISEMENT_TYPE_SPECIAL = 97;
  public static ADVERTISEMENT_TYPE_BECOME_TEACHER = 98;
  public static ADVERTISEMENT_TYPE_SEARCH_SUPPORT = 99;

  public static FILTER_TYPE_RANGE = 101;
  public static FILTER_TYPE_DICTIONARY = 102;

  public static PAGE_CONTENT = 110;
  public static PAGE_BLOCK = 111;

  public static TYPE_TEST_BEFORE_COURSE = 121;
  public static TYPE_TEST_AFTER_COURSE = 122;
  public static TYPE_TEST_LESSON = 123;

  public static TYPE_QUESTION_SINGLE_CHOICE = 131;
  public static TYPE_QUESTION_MULTIPLE_CHOICE = 132;

  public static TYPE_NOTIFICATION_SMS = 141;
  public static TYPE_NOTIFICATION_EMAIL = 142;
  public static TYPE_NOTIFICATION_TOP_SITE = 143;
  public static TYPE_NOTIFICATION_PROFILE = 144;

  public static TYPE_NOTIFICATION_RECIPIENT_STUDENT = 151;
  public static TYPE_NOTIFICATION_RECIPIENT_TEACHER = 152;
  public static TYPE_NOTIFICATION_RECIPIENT_ADMIN = 153;

  public static TYPE_REQUEST_TEACHER_BECOME_VERIFIED = 161;
  public static TYPE_REQUEST_TEACHER_BECOME_PRO = 162;

  public static TYPE_REQUEST_TEACHER_COURSE_PUBLISH = 171;
  public static TYPE_REQUEST_TEACHER_COURSE_UNPUBLISH = 172;
  public static TYPE_REQUEST_TEACHER_COURSE_VERIFY = 173;
  public static TYPE_REQUEST_TEACHER_VERIFY_CONTRACT = 174;

  public static TYPE_JOURNAL_ADD = 181;
  public static TYPE_JOURNAL_EDIT = 182;
  public static TYPE_JOURNAL_DELETE = 183;

  public static TYPE_TICKET_TEACHER = 191;
  public static TYPE_TICKET_STUDENT = 192;

  public static TYPE_SENDER_ADMIN = 201;
  public static TYPE_SENDER_TEACHER = 202;
  public static TYPE_SENDER_STUDENT = 203;

  public static TYPE_SUPPORT_ARTICLE_CATEGORY_STUDENT = 221;
  public static TYPE_SUPPORT_ARTICLE_CATEGORY_TEACHER = 222;

  public static FIELD_TYPE_NUMBER = 231;
  public static FIELD_TYPE_STRING = 232;

  public static FIELD_TYPE_TEXT = 241;
  public static FIELD_TYPE_IMAGE = 242;
  public static FIELD_TYPE_VIDEO = 243;
  public static FIELD_TYPE_HTML = 244;
  public static FIELD_TYPE_FILE = 245;
  public static FIELD_TYPE_MULTI_TEXT = 246;
  public static FIELD_TYPE_ARRAY = 247;
  public static TYPE_NO_DIVIDER = 249;

  public static TYPE_GENERAL_NEWSLETTER = 251;
  public static TYPE_ACCOUNT_STUDENT = 261;
  public static TYPE_ACCOUNT_TEACHER = 262;

  public static TYPE_PERSOANA_FIZICA = 271;
  public static TYPE_PERSOANA_JURIDICA = 272;
  public static TYPE_PERSOANA_FIZICA_AUTORIZATA = 273;

  public static _constants: { [key: number]: string } = {
    1: "ADMIN",
    2: "SITE",

    11: "SECTION_TOP",
    12: "SECTION_LEFT",
    13: "SECTION_RIGHT",
    14: "SECTION_BOTTOM",
    15: "SECTION_INFO",
    16: "SECTION_SUPPORT_FOOTER",
    17: "SECTION_SUPPORT_SECOND_FOOTER",

    21: "FAQ_GENERAL",

    31: "BLOG_GENERAL",
    41: "QUESTION_GENERAL",

    51: "TYPE_OWNER_STUDENT",

    61: "TYPE_TRANSACTION_BASIC",
    71: "TRUE_ANSWER",
    72: "FALSE_ANSWER",

    811: "SECTION_TOP_ADVERTISEMENT",
    822: "SECTION_INFO_ADVERTISEMENT",
    833: "SECTION_BOTTOM_ADVERTISEMENT",
    834: "SECTION_HOME_1",
    835: "SECTION_HOME_2",
    836: "SECTION_HOME_3",
    837: "SECTION_BENEFITS",
    838: "SECTION_SUGGESTIONS",
    839: "SECTION_BECOME_TEACHER_1",
    840: "SECTION_BECOME_TEACHER_2",
    841: "SECTION_BECOME_TEACHER_3",
    842: "SECTION_BECOME_TEACHER_4",
    843: "SECTION_BECOME_TEACHER_5",
    844: "SECTION_CREATE_NEW_COURSE",

    91: "ADVERTISEMENT_TYPE_SEARCH",
    92: "ADVERTISEMENT_TYPE_MEDIUM_BANNER",
    93: "ADVERTISEMENT_TYPE_SMALL_BANNER",
    94: "ADVERTISEMENT_TYPE_BANNER",
    95: "ADVERTISEMENT_TYPE_BANNER_IMAGE_LEFT",
    96: "ADVERTISEMENT_TYPE_BANNER_IMAGE_RIGHT",
    97: "ADVERTISEMENT_TYPE_SPECIAL",
    98: "ADVERTISEMENT_TYPE_BECOME_TEACHER",
    99: "ADVERTISEMENT_TYPE_SEARCH_SUPPORT",

    101: "FILTER_TYPE_RANGE",
    102: "FILTER_TYPE_DICTIONARY",

    110: "PAGE_CONTENT",
    111: "PAGE_BLOCK",

    121: "TYPE_TEST_BEFORE_COURSE",
    122: "TYPE_TEST_AFTER_COURSE",
    123: "TYPE_TEST_LESSON",

    131: "TYPE_QUESTION_SINGLE_CHOICE",
    132: "TYPE_QUESTION_MULTIPLE_CHOICE",

    141: "TYPE_NOTIFICATION_SMS",
    142: "TYPE_NOTIFICATION_EMAIL",
    143: "TYPE_NOTIFICATION_TOP_SITE",
    144: "TYPE_NOTIFICATION_PROFILE",

    151: "TYPE_NOTIFICATION_RECIPIENT_STUDENT",
    152: "TYPE_NOTIFICATION_RECIPIENT_TEACHER",
    153: "TYPE_NOTIFICATION_RECIPIENT_ADMIN",

    161: "TYPE_REQUEST_TEACHER_BECOME_VERIFIED",
    162: "TYPE_REQUEST_TEACHER_BECOME_PRO",

    171: "TYPE_REQUEST_TEACHER_COURSE_PUBLISH",
    172: "TYPE_REQUEST_TEACHER_COURSE_UNPUBLISH",
    173: "TYPE_REQUEST_TEACHER_COURSE_VERIFY",
    174: "TYPE_REQUEST_TEACHER_VERIFY_CONTRACT",

    181: "TYPE_JOURNAL_ADD",
    182: "TYPE_JOURNAL_EDIT",
    183: "TYPE_JOURNAL_DELETE",

    191: "TYPE_TICKET_TEACHER",
    192: "TYPE_TICKET_STUDENT",

    201: "TYPE_SENDER_ADMIN",
    202: "TYPE_SENDER_TEACHER",
    203: "TYPE_SENDER_STUDENT",

    221: "TYPE_SUPPORT_ARTICLE_CATEGORY_STUDENT",
    222: "TYPE_SUPPORT_ARTICLE_CATEGORY_TEACHER",

    251: "TYPE_GENERAL_NEWSLETTER",

    261: "TYPE_ACCOUNT_STUDENT",
    262: "TYPE_ACCOUNT_TEACHER",

    271: "TYPE_PERSOANA_FIZICA",
    272: "TYPE_PERSOANA_JURIDICA",
    273: "TYPE_PERSOANA_FIZICA_AUTORIZATA",
  };

  // -------------------------------------

  public static LL = (key: any) => {
    const k: number = parseInt(key);
    if (!this._constants.hasOwnProperty(k)) return null;
    return this._LL(
      "Types_" + this._constants[k].toString(),
      LabelTypes.LABEL_MESSAGE
    );
  };

  // -------------------------------------

  public static GA_KEYS = (key: string): Array<number> => {
    var rez: Array<number> = [];

    if (key === "clasificator") {
      rez = [this.ADMIN, this.SITE];
    }

    if (key === "blog") {
      rez = [this.BLOG_GENERAL];
    }

    if (key === "faq") {
      rez = [this.FAQ_GENERAL];
    }

    if (key === "section") {
      rez = [
        this.SECTION_TOP,
        this.SECTION_LEFT,
        this.SECTION_RIGHT,
        this.SECTION_BOTTOM,
        this.SECTION_INFO,
        this.SECTION_SUPPORT_FOOTER,
        this.SECTION_SUPPORT_SECOND_FOOTER,
      ];
    }

    if (key === "question") {
      rez = [this.QUESTION_GENERAL];
    }

    if (key === "typeowner") {
      rez = [this.TYPE_OWNER_STUDENT];
    }

    if (key === "transactiontype") {
      rez = [this.TYPE_TRANSACTION_BASIC];
    }
    if (key === "answers") {
      rez = [this.TRUE_ANSWER, this.FALSE_ANSWER];
    }
    if (key === "advertisementsection") {
      rez = [
        this.SECTION_TOP_ADVERTISEMENT,
        this.SECTION_INFO_ADVERTISEMENT,
        this.SECTION_BOTTOM_ADVERTISEMENT,
        this.SECTION_HOME_1,
        this.SECTION_HOME_2,
        this.SECTION_HOME_3,
        this.SECTION_BENEFITS,
        this.SECTION_SUGGESTIONS,
        this.SECTION_BECOME_TEACHER_1,
        this.SECTION_BECOME_TEACHER_2,
        this.SECTION_BECOME_TEACHER_3,
        this.SECTION_BECOME_TEACHER_4,
        this.SECTION_BECOME_TEACHER_5,
        this.SECTION_CREATE_NEW_COURSE,
      ];
    }
    if (key === "advertisementtype") {
      rez = [
        this.ADVERTISEMENT_TYPE_SEARCH,
        this.ADVERTISEMENT_TYPE_MEDIUM_BANNER,
        this.ADVERTISEMENT_TYPE_SMALL_BANNER,
        this.ADVERTISEMENT_TYPE_BANNER,
        this.ADVERTISEMENT_TYPE_BANNER_IMAGE_LEFT,
        this.ADVERTISEMENT_TYPE_BANNER_IMAGE_RIGHT,
        this.ADVERTISEMENT_TYPE_SPECIAL,
        this.ADVERTISEMENT_TYPE_BECOME_TEACHER,
        this.ADVERTISEMENT_TYPE_SEARCH_SUPPORT,
      ];
    }

    if (key === "filtertype") {
      rez = [this.FILTER_TYPE_RANGE, this.FILTER_TYPE_DICTIONARY];
    }

    if (key === "pagetype") {
      rez = [this.PAGE_CONTENT, this.PAGE_BLOCK];
    }

    if (key === "testtype") {
      rez = [
        this.TYPE_TEST_BEFORE_COURSE,
        this.TYPE_TEST_AFTER_COURSE,
        this.TYPE_TEST_LESSON,
      ];
    }

    if (key === "questiontype") {
      rez = [
        this.TYPE_QUESTION_SINGLE_CHOICE,
        this.TYPE_QUESTION_MULTIPLE_CHOICE,
      ];
    }

    if (key === "notificationtype") {
      rez = [
        this.TYPE_NOTIFICATION_SMS,
        this.TYPE_NOTIFICATION_EMAIL,
        this.TYPE_NOTIFICATION_TOP_SITE,
        this.TYPE_NOTIFICATION_PROFILE,
      ];
    }

    if (key === "notificationrecipient") {
      rez = [
        this.TYPE_NOTIFICATION_RECIPIENT_STUDENT,
        this.TYPE_NOTIFICATION_RECIPIENT_TEACHER,
        this.TYPE_NOTIFICATION_RECIPIENT_ADMIN,
      ];
    }

    if (key === "teacherrequesttype") {
      rez = [
        this.TYPE_REQUEST_TEACHER_BECOME_VERIFIED,
        this.TYPE_REQUEST_TEACHER_BECOME_PRO,
        this.TYPE_REQUEST_TEACHER_VERIFY_CONTRACT,
      ];
    }

    if (key === "teacherrequestcoursetype") {
      rez = [
        this.TYPE_REQUEST_TEACHER_COURSE_PUBLISH,
        this.TYPE_REQUEST_TEACHER_COURSE_UNPUBLISH,
        this.TYPE_REQUEST_TEACHER_COURSE_VERIFY,
      ];
    }

    if (key === "journaltype") {
      rez = [
        this.TYPE_JOURNAL_ADD,
        this.TYPE_JOURNAL_EDIT,
        this.TYPE_JOURNAL_DELETE,
      ];
    }

    if (key === "tickettype") {
      rez = [this.TYPE_TICKET_TEACHER, this.TYPE_TICKET_STUDENT];
    }

    if (key === "sendertype") {
      rez = [
        this.TYPE_SENDER_ADMIN,
        this.TYPE_SENDER_TEACHER,
        this.TYPE_SENDER_STUDENT,
      ];
    }

    if (key === "supportarticlecategorytype") {
      rez = [
        this.TYPE_SUPPORT_ARTICLE_CATEGORY_STUDENT,
        this.TYPE_SUPPORT_ARTICLE_CATEGORY_TEACHER,
      ];
    }

    if (key === "newsletters") {
      rez = [this.TYPE_GENERAL_NEWSLETTER];
    }

    if (key === "typeaccount") {
      rez = [this.TYPE_ACCOUNT_STUDENT, this.TYPE_ACCOUNT_TEACHER];
    }

    if (key === "typecollaboration") {
      rez = [
        this.TYPE_PERSOANA_FIZICA,
        this.TYPE_PERSOANA_JURIDICA,
        this.TYPE_PERSOANA_FIZICA_AUTORIZATA,
      ];
    }

    return rez;
  };

  public static GA = (
    key: string,
    emptyVal?: boolean,
    emptyLabel?: string
  ): Array<SelectOptions> => {
    const keys = this.GA_KEYS(key);

    const rez: Array<SelectOptions> = [];

    if (emptyVal) {
      const t = new SelectOptions();
      t.value = "";
      t.label = emptyLabel ?? Types._LL("all");
      rez.push(t);
    }

    keys.forEach((item) => {
      const t = new SelectOptions();
      t.value = item;
      t.label = Types.LL(item);
      rez.push(t);
    });

    return rez;
  };

  // -------------------------------------
}
