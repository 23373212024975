import { Box } from "@mui/material";

import { MyButton } from "components/elements/button/MyButton";
import MyTextField from "components/elements/form/mytextfield";

import { AutocompleteLocality } from "components/static/locality/AutocompleteLocality";
import { AddressFormDto } from "dto/nomenclature/address.dto";
import { useForm } from "hooks/useForm";
import { useResource } from "hooks/useResource";
import React, { useEffect } from "react";

import RequiredValidator from "validators/required.validator";

type Props = {
  defaultObj: AddressFormDto;
  onSubmit: (obj: AddressFormDto) => void;
  loading: boolean;
};

const FormInlineAddress: React.FC<Props> = ({
  defaultObj,
  onSubmit,
  loading,
}) => {
  const { LL } = useResource();
  const [obj, disabled, setObjField, setObject] = useForm<AddressFormDto>(
    defaultObj,
    RequiredValidator.getValidators([
      "street",
      "streetnumber",
      "zipcode",
      "idlocality",
    ])
  );

  useEffect(() => {
    if (!defaultObj) return;
    setObject(AddressFormDto.parseFromAddressDto(defaultObj));
  }, [defaultObj]);

  const handleSubmit = (e: React.FormEvent<any>) => {
    e.preventDefault();
    e.stopPropagation();
    if (disabled || loading) return;
    onSubmit(obj);
  };
  return (
    <Box>
      <MyTextField
        tabIndex={0}
        fullWidth
        id="street"
        name="street"
        
        _label={LL("Street")}
        
        value={obj.street}
        setObj={setObjField}
        _vresults={undefined}
      />
      <Box sx={{ mt: 3 }}>
        <MyTextField
          tabIndex={1}
          fullWidth
          id="streetnumber"
          name="streetnumber"
          _label={LL("streetnumber")}
          
          value={obj.streetnumber}
          setObj={setObjField}
          _vresults={undefined}
        />
      </Box>
      <Box sx={{ mt: 3 }}>
        <MyTextField
          tabIndex={1}
          fullWidth
          id="zipcode"
          name="zipcode"
          _label={LL("zipcode")}
          
          value={obj.zipcode}
          setObj={setObjField}
          _vresults={undefined}
        />
      </Box>
      <Box sx={{ mt: 3 }}>
        <AutocompleteLocality
          setObjField={setObjField}
          value={obj.idlocality}
          defaultObject={obj.locality}
        />
      </Box>
      <Box textAlign="center">
        <MyButton
          tabIndex={4}
          disabled={disabled || loading}
          color="primary"
          variant="contained"
          size="large"
          sx={{ mt: 4 }}
          className="btn"
          onClick={handleSubmit}
        >
          {LL("Bt_Submit")}
        </MyButton>
      </Box>
    </Box>
  );
};

export { FormInlineAddress };
