import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
import React from "react";
import { GeneralFilterSelect } from "./GeneralFilterSelect";

type Props = {
  values: string[];
  setSortCriteria: (arr: RequestSortCriteriaDTO | null) => void;
  sortCriteria: RequestSortCriteriaDTO | null;
};

const Sorter: React.FC<Props> = ({ values, setSortCriteria, sortCriteria }) => {
  const options = RequestSortCriteriaDTO.prepareSelectOptions(
    RequestSortCriteriaDTO.prepareOptions(values)
  );

  const handleSetValue = (value: string) => {
    setSortCriteria(RequestSortCriteriaDTO.parseSelectedOptionToObject(value));
  };

  
  return (
    <GeneralFilterSelect
      options={options}
      value={RequestSortCriteriaDTO.parseObjectToSelectedOption(sortCriteria)}
      setValue={handleSetValue}
      _label="sort"
      size="small"
    />
  );
};

export { Sorter };
