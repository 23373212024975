import { useCookies } from "react-cookie";
import { Config } from "tools/utils/config";
import DateTools from "tools/utils/date.tools";

export const useCookie = () => {
  const [cookies, setCookie] = useCookies();
  const setCookieValue = (key: string, value: string | boolean | number) => {
    if (!value) return;
    setCookie(key, value, {
      path: "/",
      expires: DateTools.getDate(Config.COOKIE_EXPIRES),
    });
  };

  const getCookie = (key: string, defaultValue?: string | boolean | number) => {
    if (defaultValue === undefined) defaultValue = "";
    const value = cookies[key] ?? defaultValue;
    return value;
  };

  return {
    setCookieValue,
    getCookie,
    cookies
  }
};
