import { AxiosInstance } from "axios";
import MessageDto from "dto/app/message.dto";
import RequestListDTO from "dto/app/requestlist.dto";

import { isTokenExpired } from "tools/utils/commontools";

import { MessageTypes } from "tools/types/messagetypes";
import { generateTokenUrl } from "./user/user.repository";

import { getInstance } from "api/server.api";
import { logger } from "tools/utils/logger";
type Instance = {
  instance?: AxiosInstance;
  canExecute: boolean;
};
const processAxiosResult = (data: any): any => {
  if (
    data &&
    data != undefined &&
    data != null &&
    data.meta &&
    data.meta != undefined &&
    data.meta != null
  ) {
    if (GeneralRepository.metaToolsF) {
      GeneralRepository.metaToolsF(data.meta);
    }
  }
  if (
    data &&
    data != undefined &&
    data != null &&
    data.messages &&
    data.messages != undefined &&
    data.messages != null
  ) {
    if (GeneralRepository.messagesF) {
      for (var i in data.messages) {
        GeneralRepository.messagesF(data.messages[i]);
      }
    }
  }
};

const processAxiosSuccess = (
  data: any,
  url: string,
  cb?: any,
  options?: any,
  cbparameters?: any
): any => {
  const result = data.data ? data.data : null;
  // logger("processAxiosSuccess", data);
  processAxiosResult(result);

  if (!cb) return result;

  cb(result, cbparameters, data);

  return result;
};

const isResponse500 = (error: any) => {
  return error.response.status >= 500 && error.response.status <= 550;
};

const processAxiosError = (
  error: any,
  url: string,
  cb?: any,
  options?: any,
  cbparameters?: any
): any => {
  if (error && error.code === "ERR_NETWORK") {
    GeneralRepository.handleServerError();
    return;
  }
  if (
    error &&
    error != undefined &&
    error != null &&
    error.response &&
    error.response != undefined &&
    error.response != null &&
    error.response.data &&
    error.response.data != undefined &&
    error.response.data != null
  ) {
    if (error.response && !isResponse500(error)) {
      processAxiosSuccess(error.response, url, cb, options, cbparameters);
    }
    if (isResponse500(error)) {
      const obj = new MessageDto();
      obj.code = error.response.status;
      obj.message = error.response.statusText;
      obj.mestype = MessageTypes.MESSAGE_ERROR;

      if (GeneralRepository.messagesF) {
        GeneralRepository.messagesF(obj);
      }

      GeneralRepository.handleServerError();
    } else {
      if (error.response.data.messages) {
        if (GeneralRepository.messagesF) {
          for (var i in error.response.data.messages) {
            GeneralRepository.messagesF(error.response.data.messages[i]);
          }
        }
      }
      if (error.response.status === 401) {
        processUnauthorized(cb, cbparameters);
      }
    }
  } else if (error) {
    GeneralRepository.handleServerError();
  }

  return "";
};

const processError = (cb?: any, cbParams?: any) => {
  const obj = new MessageDto();
  obj.code = "403";
  obj.message = "FORBIDDEN";
  obj.mestype = MessageTypes.MESSAGE_ERROR;

  if (GeneralRepository.messagesF) {
    GeneralRepository.messagesF(obj);
  }
  if (GeneralRepository.reloadFunction) GeneralRepository.reloadFunction();
  if (!cb) return;
  cb(cbParams);
};

const processUnauthorized = (cb?: any, cbParams?: any) => {
  GeneralRepository.handleUnauthorized();
  if (cb) cb(cbParams);
  if (GeneralRepository.reloadFunction) GeneralRepository.reloadFunction();
};

export default class GeneralRepository {
  private static idLanguage: any = false;
  private static tokenF: any = false;
  public static metaToolsF: any = false;
  public static messagesF: any = false;
  public static reloadFunction: any = false;
  public static forbiddenHashFunction: any = false;
  public static unauthorizedFunction: any = false;
  public static serverErrorFunction: any = false;
  public static isServerError: any = false;

  static setIsServerError(_isServerError: any) {
    GeneralRepository.isServerError = _isServerError;
  }

  static handleIsServerError() {
    if (GeneralRepository.isServerError) {
      GeneralRepository.isServerError();
    }
    return true;
  }

  static setServerErrorFunction(_sf: any) {
    this.serverErrorFunction = _sf;
  }

  static setIdLanguage(id: any) {
    GeneralRepository.idLanguage = id;
  }

  static setToken(tokenF: any) {
    GeneralRepository.tokenF = tokenF;
  }

  static setMetaToolsF(metaF: any) {
    GeneralRepository.metaToolsF = metaF;
  }

  static setMessagesF(mF: any) {
    GeneralRepository.messagesF = mF;
  }

  public static setReloadFunction(_rf: any) {
    this.reloadFunction = _rf;
  }
  // ---------------------------------------

  public static setForbiddenHashFunction(_fhf: any) {
    this.forbiddenHashFunction = _fhf;
  }

  public static setUnauthorizedFunction(_uf: any) {
    this.unauthorizedFunction = _uf;
  }

  static getToken() {
    if (GeneralRepository.tokenF) {
      return GeneralRepository.tokenF();
    }
    return "";
  }

  static getIdLanguage() {
    if (GeneralRepository.idLanguage) {
      return GeneralRepository.idLanguage;
    }
    return "";
  }

  static handleForbiddenHash() {
    if (GeneralRepository.forbiddenHashFunction) {
      GeneralRepository.forbiddenHashFunction();
    }
  }

  static handleUnauthorized() {
    if (GeneralRepository.unauthorizedFunction) {
      GeneralRepository.unauthorizedFunction();
    }
  }

  static handleServerError() {
    if (GeneralRepository.serverErrorFunction) {
      GeneralRepository.serverErrorFunction();
    }
  }
  // ---------------------------------------

  getAxiosInstance(options?: any): Instance {
    const instanceValue: Instance = {
      canExecute: true,
    };
    options = options ? options : {};
    const h: any = {};
    h["Content-Type"] = "application/json";

    const token = GeneralRepository.getToken();
    if (isTokenExpired(token)) instanceValue.canExecute = false;
    if (token) {
      h["Authorization"] = "Bearer " + token;
    }

    const idLanguage = GeneralRepository.getIdLanguage();
    if (idLanguage) {
      h["idLanguage"] = idLanguage;
    }

    options.headers = h;
    instanceValue.instance = getInstance(options);
    return instanceValue;
  }

  // ---------------------------------------

  async getAxios(
    url: string,
    cb?: any,
    options?: any,
    cbparameters?: any
  ): Promise<any> {
    // const instance = this.getAxiosInstance(url, options);

    options = options ? options : {};

    // const h: any = {};
    // const token = GeneralRepository.getToken();
    // let canExecute = true;
    // if (token) {
    //   if (isTokenExpired(token)) canExecute = false;
    //   h["Authorization"] = "Bearer " + token;
    // } else if (cbparameters.jwt != undefined && cbparameters.jwt) {
    //   if (isTokenExpired(cbparameters.jwt)) canExecute = false;
    //   h["Authorization"] = "Bearer " + cbparameters.jwt;
    // }

    // const idLanguage = GeneralRepository.getIdLanguage();
    // if (idLanguage) {
    //   h["idLanguage"] = idLanguage;
    // }

    // options.headers = h;
    const instance = this.getAxiosInstance(options);
    const axiosInstance = instance.instance;
    if (!axiosInstance) return;
    if (!instance.canExecute && url !== generateTokenUrl) {
      processError(cb, cbparameters);
      return;
    }
    try {
      const response = await axiosInstance.get(url, options);
      return processAxiosSuccess(response, url, cb, options, cbparameters);
    } catch (error) {
      return processAxiosError(error, url, cb, options, cbparameters);
    }

    // return await axios
    //   .get(url, options)
    //   .then(function (data: any) {
    //     return processAxiosSuccess(data, url, cb, options, cbparameters);
    //   })
    //   .catch(function (error: any) {
    //     return processAxiosError(error, url, cb, options, cbparameters);
    //   })
    //   .finally(function () {});
  }

  async postAxios(
    url: string,
    cb?: any,
    options?: any,
    cbparameters?: any
  ): Promise<any> {
    const instance = this.getAxiosInstance();

    const axiosInstance = instance.instance;
    if (!axiosInstance) return;
    if (!instance.canExecute) {
      processError(cb, cbparameters);
      return;
    }
    // return axiosInstance
    //   .post(url, JSON.stringify(options))
    //   .then(function (data: any) {
    //     return processAxiosSuccess(data, url, cb, options, cbparameters);
    //   })
    //   .catch(function (error: any) {
    //     logger("postAxios", error);
    //     return processAxiosError(error, url, cb, options, cbparameters);
    //   })
    //   .finally(function () {});

    try {
      const result = await axiosInstance.post(url, JSON.stringify(options));
      return processAxiosSuccess(result, url, cb, options, cbparameters);
    } catch (error) {
      return processAxiosError(error, url, cb, options, cbparameters);
    }
  }

  async postAxiosMultipart(
    url: string,
    cb?: any,
    options?: any,
    cbparameters?: any
  ): Promise<any> {
    const instance = this.getAxiosInstance();
    // const objClass = this;

    // logger("postAxiosMultipartpostAxiosMultipartpostAxiosMultipart", options);

    const axiosInstance = instance.instance;
    if (!axiosInstance) return;
    if (!instance.canExecute) {
      processError(cb, cbparameters);
      return;
    }
    // return axiosInstance
    //   .post(url, options, {
    //     headers: {
    //       "Content-Type": "multipart/form-data",
    //     },
    //   })
    //   .then(function (data: any) {
    //     return processAxiosSuccess(data, url, cb, options, cbparameters);
    //   })
    //   .catch(function (error: any) {
    //     return processAxiosError(error, url, cb, options, cbparameters);
    //   })
    //   .finally(function () {});
    try {
      const result = await axiosInstance.post(url, options, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return processAxiosSuccess(result, url, cb, options, cbparameters);
    } catch (error) {
      return processAxiosError(error, url, cb, options, cbparameters);
    }
  }

  // ---------------------------------------
  async putAxios(
    url: string,
    cb?: any,
    options?: any,
    cbparameters?: any
  ): Promise<any> {
    const instance = this.getAxiosInstance();
    // const objClass = this;
    const axiosInstance = instance.instance;
    if (!axiosInstance) return;
    if (!instance.canExecute) {
      processError(cb, cbparameters);
      return;
    }
    // return axiosInstance
    //   .put(url, JSON.stringify(options))
    //   .then(function (data: any) {
    //     return processAxiosSuccess(data, url, cb, options, cbparameters);
    //   })
    //   .catch(function (error: any) {
    //     return processAxiosError(error, url, cb, options, cbparameters);
    //   })
    //   .finally(function () {});

    try {
      const result = await axiosInstance.put(url, JSON.stringify(options));
      return processAxiosSuccess(result, url, cb, options, cbparameters);
    } catch (error) {
      return processAxiosError(error, url, cb, options, cbparameters);
    }
  }

  async putAxiosMultipart(
    url: string,
    cb?: any,
    options?: any,
    cbparameters?: any
  ): Promise<any> {
    const instance = this.getAxiosInstance();
    // const objClass = this;
    const axiosInstance = instance.instance;
    if (!axiosInstance) return;
    if (!instance.canExecute) {
      processError(cb, cbparameters);
      return;
    }
    // return axiosInstance
    //   .put(url, options, {
    //     headers: {
    //       "Content-Type": "multipart/form-data",
    //     },
    //   })
    //   .then(function (data: any) {
    //     return processAxiosSuccess(data, url, cb, options, cbparameters);
    //   })
    //   .catch(function (error: any) {
    //     return processAxiosError(error, url, cb, options, cbparameters);
    //   })
    //   .finally(function () {});
    try {
      const result = await axiosInstance.put(url, options, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return processAxiosSuccess(result, url, cb, options, cbparameters);
    } catch (error) {
      return processAxiosError(error, url, cb, options, cbparameters);
    }
  }

  async deleteAxios(url: string, cb?: any, cbparameters?: any): Promise<any> {
    const instance = this.getAxiosInstance();

    const axiosInstance = instance.instance;
    if (!axiosInstance) return;
    if (!instance.canExecute) {
      processError(cb, cbparameters);
      return;
    }
    // return axiosInstance
    //   .delete(url)
    //   .then(function (data: any) {
    //     return processAxiosSuccess(data, url, cb, undefined, cbparameters);
    //   })
    //   .catch(function (error: any) {
    //     return processAxiosError(error, url, cb, undefined, cbparameters);
    //   })
    //   .finally(function () {});

    try {
      const result = await axiosInstance.delete(url);

      return processAxiosSuccess(result, url, cb, undefined, cbparameters);
    } catch (error) {
      return processAxiosError(error, url, cb, undefined, cbparameters);
    }
  }

  // ---------------------------------------

  async patchAxios(
    url: string,
    cb?: any,
    options?: any,
    cbparameters?: any
  ): Promise<any> {
    const instance = this.getAxiosInstance();
    // const objClass = this;

    const axiosInstance = instance.instance;
    if (!axiosInstance) return;
    if (!instance.canExecute) {
      processError(cb, cbparameters);
      return;
    }
    // return axiosInstance
    //   .patch(url, JSON.stringify(options))
    //   .then(function (data: any) {
    //     return processAxiosSuccess(data, url, cb, options, cbparameters);
    //   })
    //   .catch(function (error: any) {
    //     return processAxiosError(error, url, cb, options, cbparameters);
    //   })
    //   .finally(function () {});

    try {
      const result = await axiosInstance.patch(url, JSON.stringify(options));
      return processAxiosSuccess(result, url, cb, options, cbparameters);
    } catch (error) {
      return processAxiosError(error, url, cb, options, cbparameters);
    }
  }

  // ---------------------------------------

  static processListData(data?: RequestListDTO) {
    var rez: any = {};
    rez.params = {};

    data = data ?? new RequestListDTO();

    if (data.page != null && data.page !== undefined) {
      rez.params.page = data.page;
    }

    if (data.onpage != null && data.onpage !== undefined) {
      rez.params.onpage = data.onpage;
    }

    if (
      data.sortcriteria != null &&
      data.sortcriteria !== undefined &&
      data.sortcriteria.length
    ) {
      let str = "";
      for (const i in data.sortcriteria) {
        str += str ? "|" : "";
        str += data.sortcriteria[i].field;
        str += ",";
        str += data.sortcriteria[i].asc ? "asc" : "";
      }
      rez.params.order = str;
    }

    if (
      data.filters != null &&
      data.filters !== undefined &&
      data.filters.length
    ) {
      let str = "";
      for (const i in data.filters) {
        str += str ? "|" : "";
        str += data.filters[i].field;
        str += ",";
        str += data.filters[i].values ? data.filters[i].values?.join(",") : "";
      }
      rez.params.filters = str;
    }

    return rez;
  }

  async _get(url: string, cb?: any, cbparameters?: any): Promise<any> {
    return await this.getAxios(url, cb, {}, cbparameters);
  }

  async _getList(
    url: string,
    cb?: any,
    cbparameters?: any,
    data?: RequestListDTO
  ): Promise<any> {
    const _data = GeneralRepository.processListData(data);
    return await this.getAxios(url, cb, _data, cbparameters);
  }
}
