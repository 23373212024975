import { Height } from "@mui/icons-material";
import MyTextField from "components/elements/form/mytextfield";
import { StatusSelect } from "components/elements/select/StatusSelect";
import { LabelDto, UpdateLabelStatusDto } from "dto/static/label.dto";
import { useForm } from "hooks/useForm";
import { useResource } from "hooks/useResource";
import React from "react";

import RequiredValidator from "validators/required.validator";

type PropsValue = {
  defaultObject: LabelDto;
  onSubmit: (object: LabelDto) => void;
};

const FormGridLabel: React.FC<PropsValue> = ({ defaultObject, onSubmit }) => {
  const { LL } = useResource();
  const [obj, disabled, setObjField] = useForm<LabelDto>(
    defaultObject,
    RequiredValidator.getValidators(["value"])
  );

  const onBlur = () => {
    if (disabled) return;
    onSubmit(obj);
  };
  return (
    <MyTextField
      variant="outlined"
      color="primary"
      _label={LL("label_text_field_value")}
      size="small"
      field={"value"}
      value={obj.value}
      setObj={setObjField}
      onBlur={onBlur}
      sx={{ m: 1 }}
    />
  );
};

type PropsStatus = {
  defaultObject: UpdateLabelStatusDto;
  onSubmit: (object: UpdateLabelStatusDto) => void;
};

const FormGridLabelStatus: React.FC<PropsStatus> = ({
  defaultObject,
  onSubmit,
}) => {
  const [obj, , setObjField] = useForm<UpdateLabelStatusDto>(
    defaultObject,
    RequiredValidator.getValidators(["status"])
  );

  const onBlur = () => {
    if (defaultObject.status?.toString() === obj.status?.toString()) return;

    onSubmit(obj);
  };
  return (
    <StatusSelect
      value={obj.status}
      setObjField={setObjField}
      onBlur={onBlur}
    />
  );
};

export { FormGridLabel, FormGridLabelStatus };
