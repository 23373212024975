import { Box, CircularProgress, Stack, Typography } from "@mui/material";

import {
  GridLabelDto,
  GridLabelValueDto,
  LabelDto,
} from "dto/static/label.dto";
import { LanguageDto } from "dto/static/language.dto";
import { useResource } from "hooks/useResource";
import React, { useState } from "react";
import { CommonTools } from "tools/utils/commontools";

import { FormGridLabel } from "./FormGridLabel";

import { Close, Done } from "@mui/icons-material";
import { LabelService } from "services/static/label.service";
import ResultObjectDTO from "dto/app/resultobject.dto";
import { Config } from "tools/utils/config";
import { FontBackgroundColor } from "ckeditor5";

type Props = {
  item: GridLabelValueDto;
  object: GridLabelDto;
};

const service = new LabelService();
const GridModelItemValue: React.FC<Props> = ({ item, object }) => {
  const { langs } = useResource();
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  if (!item) return null;

  const language =
    langs && langs.length
      ? langs.find((l: LanguageDto) => l.id === item.idlanguage)
      : null;

  const onSubmit = (data: LabelDto) => {
    if (!data) return;
    if (!CommonTools.processObjectField(data, ["id"])) return;
    setLoading(true);
    service.update(
      CommonTools.processObjectField(data, ["id"]),
      handleResult,
      {},
      data
    );
  };

  const handleResult = (result: ResultObjectDTO<LabelDto>) => {
    if (!result) {
      handleError();
      return;
    }
    if (result.err) {
      handleError();
      return;
    }
    setLoading(false);
    setSuccess(true);
    setTimeout(() => {
      setSuccess(false);
    }, Config.CLOSE_MESSAGE_SUCCESS);
  };

  const handleError = () => {
    setLoading(false);
    setError(true);
    setTimeout(() => {
      setError(false);
    }, Config.CLOSE_MESSAGE_ERROR);
  };
  return (
    <Box sx={{}}>
      {/* <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        spacing={2}
      >
        <Box width={"30%"}>
          <Typography>
            {CommonTools.processObjectField(language, ["name"])}
          </Typography>
        </Box>
        <Box width={"60%"}>
          <FormGridLabel
            defaultObject={GridLabelValueDto.toLabelDto(object, item)}
            onSubmit={onSubmit}
          />
        </Box>
        <Box width={"10%"}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              alignContent: "center",
              justifyContent: "center",
            }}
          >
            {loading && <CircularProgress size={25} color="primary" />}
            {success && <Done color="success" />}
            {error && <Close color="error" />}
          </Box>
        </Box>
      </Stack> */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "calc(100% - 8px)",
        }}>
        <Typography sx={{ fontWeight: 600 }}>
          {CommonTools.processObjectField(language, ["name"])}
        </Typography>
        <Box
          sx={{
            width: "80%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "end",
          }}>
          <Box sx={{ width: "20px" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                alignContent: "center",
                justifyContent: "center",
              }}>
              {loading && <CircularProgress size={25} color="primary" />}
              {success && <Done color="success" />}
              {error && <Close color="error" />}
            </Box>
          </Box>
          <Box sx={{ width: "100%" }}>
            <FormGridLabel
              defaultObject={GridLabelValueDto.toLabelDto(object, item)}
              onSubmit={onSubmit}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export { GridModelItemValue };
