import React from "react";
import { Box, Typography } from "@mui/material";
import { MyButton } from "components/elements/button/MyButton";

const ServerError: React.FC = () => {
  const handleReload = () => {
    window.location.reload();
  };
  return (
    // <Box
    //   sx={{
    //     width: "100%",
    //     height: "90vh",
    //     display: "flex",
    //     justifyContent: "center",
    //     alignItems: "center",
    //     flexDirection: "column",
    //   }}
    // >
    //   <Box sx={{ fontSize: "150px", fontWeight: "500" }}>500</Box>
    //   <Box sx={{ fontSize: "30px" }}>Server Error</Box>
    //   <Box mt={3}>
    //     <MyButton cb={handleReload}>Reload</MyButton>
    //   </Box>
    // </Box>
    <Box className="dialogPages">
      <Box className="imageErrorBox"></Box>
      <Box className="errorText">Server Error</Box>
      <Box className="errorTextSmall">Please wait a bit and try again.</Box>
      <Box mt={10} className="">
        <MyButton cb={handleReload} className="errorBoxButton">
          <Typography className="textButton">Reload Page</Typography>
        </MyButton>
      </Box>
    </Box>
  );
};

export { ServerError };
