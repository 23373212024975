import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import Box from "@mui/material/Box";
import FormHelperText from "@mui/material/FormHelperText";

import CheckIcon from "@mui/icons-material/Check";

import ValidatorResult from "validators/validatorresult";

type Props = {
  [x: string]: any;
  _label: string;
  setObj: (field: string, value: any) => void;
  _vresults?: ValidatorResult | null | boolean;
};
const MyTextField: React.FC<Props> = ({
  _label,
  setObj,
  _vresults,
  ...props
}) => {
  const [label, setLabel] = useState("");
  const [field, setField] = useState("");
  const [vresults, setVResults] = useState<any>(false);
  const [wasEntered, setWasEntered] = useState(false);

  const handleOnBlur = () => {
    setWasEntered(true);
  };
  useEffect(() => {
    setLabel(_label);
  }, [_label]);

  useEffect(() => {
    setVResults(_vresults);
  }, [_vresults]);

  useEffect(() => {
    const f = props.field ? props.field : props.name;
    setField(f);
  }, [props]);

  const handleChange = (event: any) => {
    if (!setObj) return;
    setObj(field, event.target.value);
  };

  const infoButton = () => {
    if (!wasEntered) return null;
    if (!vresults) return null;
    if (!vresults.hasOwnProperty(field)) return null;
    if (!vresults[field]) return null;
    if (vresults[field].isOk === "-1") return null;

    if (vresults[field].isOk) {
      return (
        <Box className="iconInput">
          <CheckIcon sx={{ color: "green" }} />
        </Box>
      );
    }

    return (
      <Box className="iconInput">
        <ReportGmailerrorredIcon sx={{ color: "red" }} />
      </Box>
    );
  };

  const infoMessages = () => {
    if (!wasEntered) return null;
    if (!vresults) return null;
    if (!vresults.hasOwnProperty(field)) return null;
    if (!vresults[field]) return null;
    if (!vresults[field].objects) return null;
    if (!vresults[field].objects.length) return null;
    if (vresults[field].isOk === "-1") return null;

    const cl = vresults[field].isOk ? "active" : "disabled";

    return vresults[field].objects.map((_obj: any, i: number) => {
      if (!_obj.message) return null;

      return (
        <FormHelperText className={cl} key={i}>
          {_obj.message}
        </FormHelperText>
      );
    });
  };

  return (
    <Box sx={{ position: "relative", right: "0px" }}>
      <TextField
        variant="outlined"
        color="primary"
        size="small"
        label={label}
        fullWidth
        onFocus={handleChange}
        onChange={handleChange}
        onBlur={handleOnBlur}
        {...props}
      />

      {infoButton()}
      {infoMessages()}
    </Box>
  );
};

export default MyTextField;
