import { Close } from "@mui/icons-material";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import ResultObjectDTO from "dto/app/resultobject.dto";

import React, { useState } from "react";

import { FormTestEmail } from "./FormTestEmail";

import MessageDto from "dto/app/message.dto";
import { MessageTypes } from "tools/types/messagetypes";
import { useResource } from "hooks/useResource";
import { EmailTemplateService } from "services/notification/emailtemplate.service";
import { SendTestEmailDto } from "dto/notification/emailtemplate.dto";
import { useMessage } from "hooks/useMessage";

type Props = {
  open: boolean;
  handleClose: () => void;
  idFromEmail?: string;
};
const service = new EmailTemplateService();
const DialogTestEmail: React.FC<Props> = ({
  open,
  handleClose,
  idFromEmail,
}) => {
  const { LL } = useResource();
  const { RegisterMessage } = useMessage();
  const [loading, setLoading] = useState(false);
  const onSubmit = (data: SendTestEmailDto) => {
    setLoading(true);
    service.sendTestEmail(handleResult, {}, data);
  };
  const handleResult = (result: ResultObjectDTO) => {
    if (!result) return;
    setLoading(false);
    handleClose();
    RegisterMessage(
      MessageDto.prepareMessage(
        "200",
        LL("Email_sent_successfully"),
        MessageTypes.MESSAGE_SUCCESS
      )
    );
  };
  return (
    <Dialog
      open={open}
      aria-labelledby="email-dialog-title"
      aria-describedby="email-dialog-description"
    >
      <DialogTitle id="email-dialog-title">{LL("Test_email")}</DialogTitle>
      <IconButton
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
        }}
        onClick={handleClose}
      >
        <Close />
      </IconButton>
      <DialogContent dividers>
        <FormTestEmail
          defaultObject={new SendTestEmailDto(idFromEmail)}
          onSubmit={onSubmit}
          loading={loading}
        />
      </DialogContent>
    </Dialog>
  );
};

export { DialogTestEmail };
