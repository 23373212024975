import React, { useCallback, useLayoutEffect } from "react";

import { useEffect, useState } from "react";

import { CookiesProvider } from "react-cookie";

import { ResourceProvider } from "providers/ResourceProvider";

import { AuthProvider } from "providers/AuthProvider";

import theme from "assets/themes/theme";

import { ThemeProvider } from "@mui/material/styles";
import { MessageProvider } from "providers/MessageProvider";
import { AppRoutes } from "providers/AppRoutes";
import GeneralRepository from "repositories/general.repository";
import { PermissionProvider } from "providers/PermissionProvider";
import { ServerError } from "components/general/ServerError";
import { JWTProvider } from "providers/JWTProvider";
import { UserProvider } from "providers/UserProvider";
import LocalStorageTools from "api/localstorage.api";
import SocketProvider from "providers/SocketProvider";

const App: React.FC = () => {
  const [_theme, setTheme] = useState({});
  const [serverError, setServerError] = useState<boolean>(false);

  useEffect(() => {
    setTheme(theme);
  }, []);

  const handleReload = () => {
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  const processServerError = () => {
    setServerError(true);
  };

  useLayoutEffect(() => {
    GeneralRepository.setReloadFunction(handleReload);
    GeneralRepository.setServerErrorFunction(processServerError);
    GeneralRepository.setUnauthorizedFunction(handleUnauthorized);
  }, []);

  const handleUnauthorized = () => {
    LocalStorageTools.saveValue("token", "");
    LocalStorageTools.saveValue("remember_token", "");
  };

  if (serverError) {
    return (
      <ThemeProvider theme={_theme}>
        <ServerError />
      </ThemeProvider>
    );
  }
  return (
    <ThemeProvider theme={_theme}>
      <CookiesProvider>
        <JWTProvider>
          <SocketProvider>
            <UserProvider>
              <PermissionProvider>
                <ResourceProvider>
                  <MessageProvider>
                    <AppRoutes />
                  </MessageProvider>
                </ResourceProvider>
              </PermissionProvider>
            </UserProvider>
          </SocketProvider>
        </JWTProvider>
      </CookiesProvider>
    </ThemeProvider>
  );
};

export { App };
