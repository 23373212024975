import IRepository from "interfaces/irepository.interface";
import { GlobalGeneralRepository } from "repositories/globalgeneral.repository";

export class EmailTemplateRepository
  extends GlobalGeneralRepository
  implements IRepository
{
  constructor() {
    super("emailtemplate");
  }

  sendTestEmail = async (cb?: any, cbParams?: any, data?: any) => {
    const url = `${this.mainUrl}/send-test`;
    return await this.postAxios(url, data, cb, cbParams);
  };
}
