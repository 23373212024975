import { Box } from "@mui/material";
import { MyPagination } from "components/elements/pagination/MyPagination";
import { OnPageSelect } from "components/elements/select/newSelect/OnPageSelect";
import { PageSelect } from "components/elements/select/newSelect/PageSelect";
import React from "react";

type Props = {
  onPage: number;
  page: number;
  setPage: (page: number) => void;
  setOnPage: (onPage: number) => void;
  totalPages: number;
};

const GridPagination: React.FC<Props> = ({
  onPage,
  page,
  setPage,
  setOnPage,
  totalPages,
}) => {
  return (
    <Box className="headerGridLabels">
      <Box className="pageSelectGridLabel">
        <PageSelect totalPages={totalPages} setPage={setPage} page={page} />
      </Box>
      <Box className="paginationGridLabel">
        <MyPagination totalPages={totalPages} page={page} setPage={setPage} />
      </Box>
      <Box className="elemntsGridLabel">
        <OnPageSelect
          onPage={onPage}
          setOnPage={setOnPage}
          values={[5, 10, 15, 25, 50]}
        />
      </Box>
    </Box>
  );
};

export { GridPagination };
