import GeneralService from "services/globalgeneral.service";
import IService from "interfaces/iservice.interface";

import { FromEmailRepository } from "repositories/notification/fromemail.repository";

export class FromEmailService
  extends GeneralService<FromEmailRepository>
  implements IService
{
  constructor() {
    super(new FromEmailRepository(), [], "email");
  }
}
