import { Pagination } from "@mui/material";
import React from "react";

type Props = {
  totalPages: number;
  page: number;
  setPage: (page: number) => void;
};

const MyPagination: React.FC<Props> = ({ totalPages, page, setPage }) => {
  const handleChange = (event: React.ChangeEvent<any>, value: number) => {
    setPage(value);
  };
  return (
    <Pagination
      count={totalPages}
      page={page}
      onChange={handleChange}
      showFirstButton
      showLastButton
    />
  );
};

export { MyPagination };
