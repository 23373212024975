import { Grid } from "@mui/material";

import { CourseFilterDto } from "dto/course/coursefilter.dto";
import { IPage } from "interfaces/page.interface";
import React from "react";
import { CourseFilterService } from "services/course/coursefilter.service";

import { DataDisplayBox } from "components/elements/display/DataDisplayBox";

import { HeaderDetailSpecial } from "components/elements/detail/HeaderDetailSpecial";
import { Loading } from "components/elements/loading/Loading";
import { DetailGrid } from "components/elements/generalcomponents/DetailGrid";
import { useDetailSpecial } from "hooks/useDetailSpecial";

const service = new CourseFilterService();
const SpecialCourseFilterDetail: React.FC<IPage> = ({
  currentRoute,
  mainObject,
}) => {
  const get = (id: string, cb: any, cbParams: any) => {
    service.get(id, cb, cbParams);
  };

  const {
    mainUrl,
    editUrl,
    parentType,
    parentId,
    object,
    loading,
    mainListUrl,
    listUrlSecond
  } = useDetailSpecial<CourseFilterDto>("coursefilter", currentRoute, get);

  if (!currentRoute) return null;
  if (!mainObject) return null;
  if (loading) return <Loading />;
  if (!object) return null;

  return (
    <React.Fragment>
      <HeaderDetailSpecial
        object={object}
        mainObject={mainObject}
        mainUrl={mainUrl}
        parentId={parentId}
        parentType={parentType}
        editUrl={editUrl}
        specialType="coursefilter"
        mainListUrl={mainListUrl}
        urlListSecond={listUrlSecond}
      />
      <DataDisplayBox>
        <Grid>
          <DetailGrid
            object={object}
            fields={CourseFilterDto.getDetailFields()}
          />
        </Grid>
      </DataDisplayBox>
    </React.Fragment>
  );
};

export { SpecialCourseFilterDetail };
