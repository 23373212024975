// import React, { useEffect, useState } from "react";
// import Select, { SelectChangeEvent } from "@mui/material/Select";
// import { logger } from "tools/utils/logger";
// import { useResource } from "hook/useResource";

// import { MySelect } from "components/elements/Select/MySelect";
// import { Status } from "tools/types/status"
// import SelectOptions from "dto/app/selectoptions.dto";
// import { Skeleton } from "@mui/material";
// type StatusSelectProps = {
//     setObj: ((field: any, value: any) => void);
//     field: any;
//     _defaultValue?: number;
//     statusType?: string;
//     _label?: string;
//     [key: string]: any; // for the rest of the props which we do not have types for
// }

// const StatusSelect: React.FC<StatusSelectProps> = ({
//     setObj,
//     field,
//     _defaultValue,
//     statusType,
//     _label,
//     ...props
// }) => {
//     const { LL } = useResource();

//     const defaultValue = _defaultValue ? _defaultValue : Status.ACTIVE;
//     const label = _label ? _label : "Status";
//     const [options, setOptions] = useState<Array<SelectOptions>>(new Array<SelectOptions>());
//     const [loading, setLoading] = useState<boolean>(true);
//     const [value, setValue] = useState<number | any>();

//     useEffect(() => {
        
//         if (!statusType) statusType = 'clasificator';
//         const options: Array<SelectOptions> = Status.GA(statusType);
//         setOptions(options);
        
//         // if (!value && !_defaultValue) setValue(Status.ACTIVE);
//     }, [statusType]);

//     useEffect(() => {
//         setValue('')
//         if (_defaultValue) {
//             setValue(_defaultValue);
//         }
//         if (!value && !_defaultValue) {
//             setValue(Status.ACTIVE);
//         }
//     }, [_defaultValue]);

//     useEffect(() => {
//         if (value) setObj(field, value);
//     }, [value]);

//     // useEffect(() => {
//     //     if (_defaultValue) setValue(_defaultValue);
//     //     if (!value && !_defaultValue) setValue(Status.ACTIVE);
//     // }, [options]);

//     return options.length > 0 ? (
//         <MySelect
//             options={options}
//             _label={label}
//             setValue={setValue}
//             value={value}
//             {...props}
//         />
//     ) : (<Skeleton variant="rectangular" height={50} />);
//     // const processOptions = () => {
//     //     if (!statusType) statusType = 'clasificator';
//     //     const options: Array<SelectOptions> = Status.GA(statusType);
//     //     setOptions(options);
//     //     if (!_defaultValue && !value) setValue(Status.ACTIVE);
//     // }


//     // useEffect(() => {
//     //     processOptions()
//     // }, []);

//     // useEffect(() => {
//     //     checkIsLoading();
//     // }, [options]);

//     // useEffect(() => {
//     //     if (value) setObj(field, value);
//     // }, [value]);

//     // useEffect(() => {
//     //     if (_defaultValue) setValue(_defaultValue);
//     // }, [_defaultValue]);

//     // const checkIsLoading = () => {
//     //     let load: boolean = false;
//     //     if (options === undefined) load = true;
//     //     if (options.length === 0) load = true;

//     //     setLoading(load);

//     // };

//     // return !loading && options.length > 0 ? (
//     //     <MySelect
//     //         options={options}
//     //         _label={label}
//     //         setValue={setValue}
//     //         value={value}
//     //         {...props}
//     //     />
//     // ) : (<Skeleton variant="rectangular" height={50} />);
// };

// export { StatusSelect };

import React from "react";
import { MySelect } from "./newSelect/MySelect";
import { Status } from "tools/types/status";


type Props = {
  typeStatus?: string;
  label?: string;
  setObjField: (field: string, value: any) => void;
  value: string | number | undefined;
  field?: string;
  onBlur?: () => void;
};
const StatusSelect: React.FC<Props> = ({
  typeStatus = "clasificator",
  label = "select_status",
  setObjField,
  value,
  field = "status",
  onBlur,
}) => {
  return (
    <MySelect
      options={Status.GA(typeStatus)}
      _label={label}
      setObjectField={setObjField}
      value={value}
      field={field}
      onBlur={onBlur}
      
    />
  );
};

export { StatusSelect };
