import Idto from "interfaces/idto.interface";
import SelectOptions from "./selectoptions.dto";
import { RouteTools } from "tools/utils/routetools";

export default class RequestSortCriteriaDTO implements Idto {
  field?: string;
  asc?: boolean;
  constructor(field?: string, asc?: boolean) {
    this.field = field || "";
    this.asc = asc || false;
  }
  static prepareSortCriteria(
    field: string,
    asc: boolean
  ): RequestSortCriteriaDTO {
    return new RequestSortCriteriaDTO(field, asc);
  }

  static prepareOptions(values: string[]): RequestSortCriteriaDTO[] {
    const arr: RequestSortCriteriaDTO[] = [];
    values.forEach((value) => {
      arr.push(new RequestSortCriteriaDTO(value, true));
      arr.push(new RequestSortCriteriaDTO(value, false));
    });
    return arr;
  }

  static prepareSelectOptions(arr: RequestSortCriteriaDTO[]): SelectOptions[] {
    const arrOptions: SelectOptions[] = [];
    arr.forEach((item) => {
      arrOptions.push(
        new SelectOptions(
          item.asc ? `${item.field}_asc` : `${item.field}_desc`,
          RouteTools._LL(item.asc ? `${item.field}_asc` : `${item.field}_desc`)
        )
      );
    });
    return arrOptions;
  }

  static parseSelectedOptionToObject(option: string): RequestSortCriteriaDTO {
    return new RequestSortCriteriaDTO(
      option.replace("_asc", "").replace("_desc", ""),
      option.toString().includes("_asc")
    );
  }

  static parseObjectToSelectedOption(
    obj: RequestSortCriteriaDTO | null
  ): string {
    if (!obj) return "";
    return `${obj.field}_${obj.asc ? "asc" : "desc"}`;
  }
}
