import SelectOptions from "dto/app/selectoptions.dto";
import TbFilterListDTO from "dto/app/tbfilterlist.dto";
import { DetailObject } from "interfaces/detailobject.interface";
import Idto from "interfaces/idto.interface";

import { Types } from "tools/types/types";
import TbFilterDTO from "dto/app/tbfilter.dto";

import { Status } from "tools/types/status";
import { Params } from "dto/system/params.dto";
import { ComponentCommonTools } from "tools/utils/componentcommontools";
import FilterTools from "tools/utils/filtertools";
import { CommonTools } from "tools/utils/commontools";

export class AppNotifyTemplateDto implements Idto {
  id?:  string;
  identifier?: string;
  status?: number;
  destinationtype?: number;
  
  idlanguage?:  string;
  
  title?: string;
  message?: string;
  parameters: Array<Params>;

  constructor(
    idlanguage?: string,
    id?:  string,
    identifier?: string,
    status?: number,
    destinationtype?: number,
    title?: string,
    message?: string
  ) {
    this.id = id || "";
    this.identifier = identifier || "";
    this.status = status || Status.ACTIVE;
    this.destinationtype = destinationtype || Types.TYPE_NOTIFICATION_RECIPIENT_ADMIN;

    this.idlanguage = idlanguage || "";
    
    this.title = title || "";
    this.message = message || "";
    this.parameters = [];
  }

  static getDetailFields(): Array<DetailObject> {
    return [
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "identifier",
        value: ["identifier"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "status",
        value: ["status_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "destinationtype",
        value: ["destinationtype_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "field_title",
        value: ["title"],
      },
      {
        typeField: Types.FIELD_TYPE_HTML,
        label: "message",
        value: ["message"],
      },
    ];
  }

  static getColumns(
    LL: (str: string) => void,
    mainObj: string,
    deleteFun?: (obj: any) => void,
    cbParent?: any,
    specialType?: string
  ): Array<any> {
    return [
      ComponentCommonTools.columns_GetCheck(),
      ComponentCommonTools.columns_StandardColumn(LL, "identifier"),
      ComponentCommonTools.columns_ConstantColumn(LL, "status"),
      ComponentCommonTools.columns_ConstantColumn(LL, "destinationtype"),
        ComponentCommonTools.columns_StandardColumn(LL, "title"),
      ComponentCommonTools.columns_ActionsColumn(
        LL,
        mainObj,
        deleteFun,
        ["edit", "detail", "delete"],
        ["detail"],
        cbParent,
        specialType
      ),
    ];
  }

  static getFilters(): TbFilterListDTO {
    const obj = new TbFilterListDTO();
    let t: TbFilterDTO;

    t = new TbFilterDTO("search", FilterTools.fiterTypeText);
    obj.addFilter(t);
    return obj;
  }

  static parseToSelectOptions(
    data: Array<AppNotifyTemplateDto>
  ): Array<SelectOptions> {
    if (!data) return [];
    if (!Array.isArray(data)) return [];
    if (!data.length) return [];
    let result: Array<SelectOptions> = [];
    data.forEach((element) => {
      result.push(AppNotifyTemplateDto.parseOption(element));
    });
    return result;
  }

  static parseOption = (obj: AppNotifyTemplateDto): SelectOptions => {
    return new SelectOptions(
      CommonTools.processObjectField(obj, ["id"]),
      CommonTools.processObjectField(obj, ["identifier"])
    );
  };
}

