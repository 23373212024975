import ResultSignInDTO from "dto/auth/resultsignin.dto";
import { UserDto } from "dto/user/user.dto";
import { UserSettingsDto } from "dto/user/usersettings.dto";
import Idto from "interfaces/idto.interface";
import { jwtDecode } from "jwt-decode";

export class JWTDto implements Idto {
  remembertoken: string;
  jwttoken: TokenDto;

  constructor(remembertoken?: string, jwttoken?: TokenDto) {
    this.remembertoken = remembertoken ?? "";
    this.jwttoken = jwttoken ?? new TokenDto();
  }
}

export class TokenDto implements Idto {
  token: string;
  u_id: string;
  u_email: string;
  u_statuspassword: number | null;
  u_mustchangepassword: boolean | null;
  u_settings_id: string;
  u_settings_name: string;
  u_settings_surname: string;
  u_settings_birthday: Date | null;
  u_roles: string[];
  u_settings_idtypegender: string | null;
  u_settings_idlanguage: string | null;
  u_settings_idavatar: string | null;

  s_id: string | null;
  s_iduser: string | null;
  s_status: number | null;
  s_idtypestudies: string | null;
  s_notificationtype1: boolean | null;
  s_notificationtype2: boolean | null;
  s_notificationtype3: boolean | null;
  s_fullurl: string | null;
  t_iduser: string | null;
  t_id: string | null;
  t_idtypespecialty: string | null;
  t_idtypetargetgroup: string | null;
  t_idtypeinternetconnection: string | null;
  t_idtypehardware: string | null;
  t_idtypelesson: string | null;
  t_idtypediscipline: string | null;
  t_idtypeoccupation: string | null;
  t_idtypestudies: string | null;
  t_idtypeprofessionalismlevel: string | null;
  t_idtypeexperience: string | null;
  t_status: number | null;
  t_notificationtype1: boolean | null;
  t_notificationtype2: boolean | null;
  t_notificationtype3: boolean | null;
  t_fullurl: string | null;
  t_numberofcourses: number | null;

  constructor(
    token?: string,
    u_id?: string,
    u_email?: string,
    u_statuspassword?: number | null,
    u_mustchangepassword?: boolean | null,
    u_settings_id?: string,
    u_settings_name?: string,
    u_settings_surname?: string,
    u_settings_birthday?: Date | null,
    u_settings_idtypegender?: string | null,
    u_settings_idlanguage?: string | null,
    u_settings_idavatar?: string | null,
    u_roles?: string[],
    s_id?: string | null,
    s_iduser?: string | null,
    s_status?: number | null,
    s_idtypestudies?: string | null,
    s_notificationtype1?: boolean | null,
    s_notificationtype2?: boolean | null,
    s_notificationtype3?: boolean | null,
    s_fullurl?: string | null,
    t_iduser?: string | null,
    t_id?: string | null,
    t_idtypespecialty?: string | null,
    t_idtypetargetgroup?: string | null,
    t_idtypeinternetconnection?: string | null,
    t_idtypehardware?: string | null,
    t_idtypelesson?: string | null,
    t_idtypediscipline?: string | null,
    t_idtypeoccupation?: string | null,
    t_idtypestudies?: string | null,
    t_idtypeprofessionalismlevel?: string | null,
    t_idtypeexperience?: string | null,
    t_status?: number | null,
    t_notificationtype1?: boolean | null,
    t_notificationtype2?: boolean | null,
    t_notificationtype3?: boolean | null,
    t_fullurl?: string | null,
    t_numberofcourses?: number | null
  ) {
    this.token = token ?? "";
    this.u_id = u_id ?? "";
    this.u_email = u_email ?? "";
    this.u_statuspassword = u_statuspassword ?? null;
    this.u_mustchangepassword = u_mustchangepassword ?? null;
    this.u_roles = u_roles ?? [];
    this.u_settings_id = u_settings_id ?? "";
    this.u_settings_name = u_settings_name ?? "";
    this.u_settings_surname = u_settings_surname ?? "";
    this.u_settings_birthday = u_settings_birthday ?? null;
    this.u_settings_idtypegender = u_settings_idtypegender ?? null;
    this.u_settings_idlanguage = u_settings_idlanguage ?? null;
    this.u_settings_idavatar = u_settings_idavatar ?? null;
    this.s_id = s_id ?? null;
    this.s_iduser = s_iduser ?? null;
    this.s_status = s_status ?? null;
    this.s_idtypestudies = s_idtypestudies ?? null;
    this.s_notificationtype1 = s_notificationtype1 ?? null;
    this.s_notificationtype2 = s_notificationtype2 ?? null;
    this.s_notificationtype3 = s_notificationtype3 ?? null;
    this.s_fullurl = s_fullurl ?? null;
    this.t_iduser = t_iduser ?? null;
    this.t_id = t_id ?? null;
    this.t_idtypespecialty = t_idtypespecialty ?? null;
    this.t_idtypetargetgroup = t_idtypetargetgroup ?? null;
    this.t_idtypeinternetconnection = t_idtypeinternetconnection ?? null;
    this.t_idtypehardware = t_idtypehardware ?? null;
    this.t_idtypelesson = t_idtypelesson ?? null;
    this.t_idtypediscipline = t_idtypediscipline ?? null;
    this.t_idtypeoccupation = t_idtypeoccupation ?? null;
    this.t_idtypestudies = t_idtypestudies ?? null;
    this.t_idtypeprofessionalismlevel = t_idtypeprofessionalismlevel ?? null;
    this.t_idtypeexperience = t_idtypeexperience ?? null;
    this.t_status = t_status ?? null;
    this.t_notificationtype1 = t_notificationtype1 ?? null;
    this.t_notificationtype2 = t_notificationtype2 ?? null;
    this.t_notificationtype3 = t_notificationtype3 ?? null;
    this.t_fullurl = t_fullurl ?? null;
    this.t_numberofcourses = t_numberofcourses ?? null;
  }

  static parseUserDto(token: TokenDto): UserDto {
    const user = new UserDto();
    user.id = token.u_id;
    user.email = token.u_email;
    if (token.u_statuspassword) user.statuspassword = token.u_statuspassword;

    return user;
  }

  static parseRoles(token: TokenDto): string[] {
    return token.u_roles;
  }

  static parseUserSettingsDto(token: TokenDto): UserSettingsDto {
    const userSettings = new UserSettingsDto();
    userSettings.id = token.u_settings_id;
    userSettings.name = token.u_settings_name;
    userSettings.surname = token.u_settings_surname;
    if (token.u_settings_birthday)
      userSettings.birthday = token.u_settings_birthday;
    if (token.u_settings_idtypegender)
      userSettings.idtypegender = token.u_settings_idtypegender;
    if (token.u_settings_idlanguage)
      userSettings.idlanguage = token.u_settings_idlanguage;
    if (token.u_settings_idavatar)
      userSettings.idavatar = token.u_settings_idavatar;

    return userSettings;
  }

  static parseResultSignInDTO(token: TokenDto): ResultSignInDTO {
    const result = new ResultSignInDTO();
    if (token.u_id) result.obj = this.parseUserDto(token);
    result.roles = this.parseRoles(token);
    if (token.u_settings_id)
      result.usersettings = this.parseUserSettingsDto(token);
    result.mustchangepassword =
      token.u_mustchangepassword !== null ? token.u_mustchangepassword : false;
    return result;
  }

  static parseTokenDto(token: string): TokenDto {
    const object = jwtDecode<TokenDto>(token);
    return new TokenDto(
      token,
      object.u_id,
      object.u_email,
      object.u_statuspassword,
      object.u_mustchangepassword,
      object.u_settings_id,
      object.u_settings_name,
      object.u_settings_surname,
      object.u_settings_birthday,
      object.u_settings_idtypegender,
      object.u_settings_idlanguage,
      object.u_settings_idavatar,
      object.u_roles,
      object.s_id,
      object.s_iduser,
      object.s_status,
      object.s_idtypestudies,
      object.s_notificationtype1,
      object.s_notificationtype2,
      object.s_notificationtype3,
      object.s_fullurl,
      object.t_iduser,
      object.t_id,
      object.t_idtypespecialty,
      object.t_idtypetargetgroup,
      object.t_idtypeinternetconnection,
      object.t_idtypehardware,
      object.t_idtypelesson,
      object.t_idtypediscipline,
      object.t_idtypeoccupation,
      object.t_idtypestudies,
      object.t_idtypeprofessionalismlevel,
      object.t_idtypeexperience,
      object.t_status,
      object.t_notificationtype1,
      object.t_notificationtype2,
      object.t_notificationtype3,
      object.t_fullurl
    );
  }

  static getResultSignInDTO(token: string): ResultSignInDTO {
    return this.parseResultSignInDTO(this.parseTokenDto(token));
  }
}
