import { Box } from "@mui/material";

import { useForm } from "hooks/useForm";

import React from "react";
import RequiredValidator from "validators/required.validator";
import { SelectFromEmail } from "components/notification/fromemail/SelectFromEmail";
import { SendTestEmailDto } from "dto/notification/emailtemplate.dto";
import { useResource } from "hooks/useResource";
import MyTextField from "components/elements/form/mytextfield";
import MyCKEditor from "components/elements/ckeditor/MyCKEditor";
import { MyButton } from "components/elements/button/MyButton";

type Props = {
  defaultObject: SendTestEmailDto;
  onSubmit: (data: SendTestEmailDto) => void;
  loading: boolean;
};

const FormTestEmail: React.FC<Props> = ({
  defaultObject,
  onSubmit,
  loading,
}) => {
  const { LL } = useResource();

  const [obj, disabled, setObjField,] = useForm<SendTestEmailDto>(
    defaultObject,
    RequiredValidator.getValidators(["toemail", "idfromemail"])
  );

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onSubmit(obj);
  };
  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Box>
          <SelectFromEmail
            setObjectField={setObjField}
            value={obj.idfromemail}
          />
        </Box>
        <Box mt={3}>
          <MyTextField
            field="toemail"
            _label={LL("toemail")}
            setObj={setObjField}
            value={obj.toemail}
            
          />
        </Box>
        <Box mt={3}>
          <MyTextField
            field="cc"
            _label={LL("cc")}
            setObj={setObjField}
            value={obj.cc}
            
          />
        </Box>
        <Box mt={3}>
          <MyTextField
            field="bcc"
            _label={LL("bcc")}
            setObj={setObjField}
            value={obj.bcc}
            
          />
        </Box>
        <Box mt={3}>
          <MyTextField
            field="subject"
            _label={LL("subject")}
            setObj={setObjField}
            value={obj.subject}
            
          />
        </Box>
        <Box mt={3} maxWidth={"400px"}>
          <MyCKEditor
            field="message"
            setObj={setObjField}
            data={obj.message}
            
          />
        </Box>
        <Box mt={3}>
          <MyButton
            type="submit"
            disabled={loading || disabled}
            
          >
            {LL("btn_submit")}
          </MyButton>
        </Box>
      </form>
    </Box>
  );
};

export { FormTestEmail };
