import Idto from "interfaces/idto.interface";

export default class RequestPopulateDTO implements Idto {
  populates?: string[];

  static prepare(populates: string[] | undefined): RequestPopulateDTO {
    const obj = new RequestPopulateDTO();
    obj.populates = populates || [];
    return obj;
  }
}
