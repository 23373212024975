import Idto from "interfaces/idto.interface";

export default class MessageDto implements Idto {
  id?: string; 
  code?: string;
  message?: string;
  mestype?: number;
  static prepareMessage(code: string, message: string, type: number) {
    const messageDto = new MessageDto();
    messageDto.code = code;
    messageDto.message = message;
    messageDto.mestype = type;
    return messageDto;
  }
}
