import {
  Paper,
  Stack,
  Typography,
  Box,
  IconButton,
  Divider,
  Button,
} from "@mui/material";
import {
  GridLabelDto,
  GridLabelValueDto,
  LabelDto,
} from "dto/static/label.dto";
import { useResource } from "hooks/useResource";
import React from "react";
import { CommonTools } from "tools/utils/commontools";
import { GridModelItemValue } from "./GridModelItemValue";
import { FormGridLabelStatus } from "./FormGridLabel";
import ResultObjectDTO from "dto/app/resultobject.dto";
import { LabelService } from "services/static/label.service";
import { Delete } from "@mui/icons-material";

type Props = {
  item: GridLabelDto;
  setObjectToDelete: (object: GridLabelDto) => void;
};

const service = new LabelService();
const GridModeItem: React.FC<Props> = ({ item, setObjectToDelete }) => {
  const { LL, langs } = useResource();
  if (!item) return null;
  const values: GridLabelValueDto[] = GridLabelValueDto.prepareForm(
    langs,
    item.allvalues || []
  );

  const onSubmit = (data: LabelDto) => {
    if (!data) return;
    if (!CommonTools.processObjectField(data, ["id"])) return;
    service.update(
      CommonTools.processObjectField(data, ["id"]),
      handleResult,
      {},
      data
    );
  };

  const handleResult = (result: ResultObjectDTO<LabelDto>) => {
    if (!result) {
      return;
    }
    if (result.err) {
      return;
    }
  };

  const handleDelete = () => {
    if (!item) return;
    setObjectToDelete(item);
  };
  return (
    <Box className="cardGridLabels">
      <Paper
        elevation={3}
        sx={{
          width: "100%",
          mb: 1,
          maxWidth: "780px",
          py: 4,
          px: 4,
        }}>
        <Box m={2}>
          <Box className="cardGridLabelsBox">
            <Typography variant="h6" sx={{ fontWeight: 500 }}>
              {LL("identifier")}
            </Typography>
            <Typography variant="h6" sx={{ fontWeight: 600 }}>
              {CommonTools.processObjectField(item, ["identifier"])}
            </Typography>
          </Box>
          <Box sx={{ paddingTop: 2 }}>
            <Divider sx={{ borderColor: "primary.main" }} />
          </Box>
          <Box className="cardGridLabelsType">
            <Box mt={2} className="labelsRowFields">
              <Typography sx={{ fontWeight: 600 }}>{LL("type")}</Typography>
              <Typography>
                {CommonTools.processObjectField(item, ["type_name"])}
              </Typography>
            </Box>
            <Box mt={1} className="labelsRowFields">
              <Typography sx={{ fontWeight: 600 }}>{LL("status")}</Typography>
              <Box sx={{ width: "150px" }}>
                <FormGridLabelStatus
                  defaultObject={GridLabelDto.toLabelDto(item)}
                  onSubmit={onSubmit}
                />
              </Box>
            </Box>
          </Box>
          <Box>
            {values.map((object, index) => (
              <Box sx={{ mt: 2 }}>
                <GridModelItemValue key={index} item={object} object={item} />
              </Box>
            ))}
          </Box>
        </Box>
        <Box className="deleteDividerButton">
          <Box sx={{ width: "calc(100% - 168px)" }}>
            <Divider sx={{ borderColor: "primary.main" }} />
          </Box>
          <Button
            variant="contained"
            color="error"
            className="deleteButton"
            onClick={handleDelete}>
            <Box className="buttonText">
              <Delete />
              {LL("deleteLabel")}
            </Box>
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

export { GridModeItem };
