import ResultSignInDTO from "dto/auth/resultsignin.dto";
import SignInDto from "dto/auth/signin.dto";

import { JWTDto, TokenDto } from "dto/system/jwt.dto";

import { UserDto } from "dto/user/user.dto";
import { UserSettingsDto } from "dto/user/usersettings.dto";
import IProvider from "interfaces/provider.interface";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { UserService } from "services/user/user.service";
import { UserSettingsService } from "services/user/usersettings.service";

import { JWTContext } from "./JWTProvider";
import { CommonTools, isTokenExpired } from "tools/utils/commontools";
import LocalStorageTools from "api/localstorage.api";
import { RouteTools } from "tools/utils/routetools";
import ResultObjectDTO from "dto/app/resultobject.dto";
import { Loading } from "components/elements/loading/Loading";


type Props = {
  user: UserDto | null;
  roles: string[];
  usersettings: UserSettingsDto | null;
  signIn: (signInDto: SignInDto, r?: boolean) => void;
  updateUserSettings: (field: string, value: any) => void;
  logout: () => void;
};

export const UserContext = createContext<Props>({
  user: null,
  roles: [],
  usersettings: null,
  signIn: () => {},
  updateUserSettings: () => {},
  logout: () => {},
});

const service = new UserService();
const userSettingsService = new UserSettingsService();

export const UserProvider: React.FC<IProvider> = ({ children }) => {
  const { token, processToken } = useContext(JWTContext);
  
  const [user, setUser] = useState<UserDto | null>(processUser(token));
  const [roles, setRoles] = useState<string[]>(processRoles(token));
  const [usersettings, setUserSettings] = useState<UserSettingsDto | null>(
    processUserSettings(token)
  );

  const [rememberToken, setRememberToken] = useState<string>(
    getLocalRememberToken()
  );

  const [nextLocation, setNextLocation] = useState<any>(false);
  const [loading, setLoading] = useState(false);

  const updateUserSettings = (field: string, value: any) => {
    if (!usersettings) return;
    const t = usersettings;
    (t as any)[field] = value;
    if (!t.id) return;
    userSettingsService.update(t.id, undefined, {}, t);
    setUserSettings(t);
  };

  const processRememberToken = (token: string) => {
    if (!token) return;
    LocalStorageTools.saveValue("remember_token", token);
    setRememberToken(token);
  };

  const signInRememberToken = useCallback(() => {
    if (!rememberToken) return;
    if (user) return;
    if (isTokenExpired(rememberToken)) {
      clearState();
      return;
    }
    setLoading(true);
    service.signInHash(
      {
        hash: rememberToken,
      },
      handleResultAuth,
      { remember: true, signInHash: true }
    );
  }, [rememberToken, user]);

  useEffect(() => {
    signInRememberToken();
  }, [signInRememberToken]);

  const handleResultAuth = (result: ResultSignInDTO, cbParams: any) => {
    if (!result) return;
    if (result.err) return;
    const token = CommonTools.processObjectField(result, ["jwttoken", "token"]);
    if (!token) return;
    setUser(processUser(token));
    setRoles(processRoles(token));
    setUserSettings(processUserSettings(token));

    processToken(token);
    if (cbParams && cbParams.remember) {
      const rememberToken = CommonTools.processObjectField(result, [
        "remembertoken",
      ]);
      processRememberToken(rememberToken);
    }

    if (cbParams && cbParams.signInHash) {
      setLoading(false);
    }

    if (cbParams && cbParams.cbFunction) {
      cbParams.cbFunction();
    }
  };

  const processUserLocation = useCallback(() => {
    if (
      user &&
      (window.location.pathname === "/login" ||
        window.location.pathname === "/signup")
    ) {
      let u = "";
      if (nextLocation) {
        u = nextLocation.pathname + nextLocation.search;
      } else {
        u = "/";
      }

      RouteTools.setHistory(u, {});
      setNextLocation(null);
    } else if (
      !user &&
      window.location.pathname !== "/login" &&
      window.location.pathname !== "/signup"
    ) {
      if (!nextLocation) {
        const t = {
          pathname: window.location.pathname,
          search: window.location.search,
        };
        setNextLocation(t);
      }
      RouteTools.setHistory("/login", {});
    }
  }, [user, nextLocation]);

  useEffect(() => {
    processUserLocation();
  }, [processUserLocation]);

  const signIn = (signInDto: SignInDto, r?: boolean) => {
    r = r !== undefined ? r : false;
    const cbp: any = {};
    cbp.remember = r;

    service.signIn(signInDto, handleResultAuth, cbp);
  };

  const logout = () => {
    service.logout(handleLogout, {});
  };
  const handleLogout = (result: ResultObjectDTO<JWTDto>) => {
    if (!result) return;
    if (result.err) return;
    if (!result.obj) return;
    const token = result.obj.jwttoken.token;
    processToken(token);
    RouteTools.setHistory("/", {});
    clearState();
  };

  const clearState = () => {
    setUser(null);
    setRoles([]);
    setUserSettings(null);

    setRememberToken("");
    LocalStorageTools.saveValue("remember_token", "");
  };

  const value = {
    user,
    roles,
    usersettings,

    setNextLocation,

    updateUserSettings,
    signIn,

    logout,
  };

  if (loading) return <Loading />;
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

const processTokenData = (token: string | null): ResultSignInDTO | null => {
  if (!token) return null;
  return TokenDto.getResultSignInDTO(token);
};

const processUser = (token: string | null) => {
  const data = processTokenData(token);
  if (!data) return null;
  if (!data.obj) return null;
  return data.obj;
};

const processRoles = (token: string | null) => {
  const data = processTokenData(token);
  if (!data) return [];
  return data.roles ?? [];
};

const processUserSettings = (token: string | null) => {
  const data = processTokenData(token);
  if (!data) return null;
  return data.usersettings ?? null;
};

const getLocalRememberToken = () => {
  const token = LocalStorageTools.getValue("remember_token");
  if (!token) return "";
  return token;
};

