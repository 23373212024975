import React from "react";

import { IPage } from "interfaces/page.interface";
import { CourseFilterService } from "services/course/coursefilter.service";
import { CourseFilterDto } from "dto/course/coursefilter.dto";

import { FormCourseFilter } from "components/special/coursefilter/FormCourseFilter";
import { DataDisplayBox } from "components/elements/display/DataDisplayBox";
import { Box } from "@mui/material";

import { useEditSpecial } from "hooks/useEditSpecial";
import { HeaderEditSpecial } from "components/elements/edit/HeaderEditSpecial";
import { Loading } from "components/elements/loading/Loading";
import { CommonTools } from "tools/utils/commontools";
import { ComponentCommonTools } from "tools/utils/componentcommontools";

const service = new CourseFilterService();
const SpecialCourseFilterEdit: React.FC<IPage> = ({
  currentRoute,
  mainObject,
}) => {
  const get = (id: string, cb: any, cbParams: any) => {
    service.get(id, cb, cbParams);
  };

  const {
    mainUrl,
    detailUrl,
    object,
    loading,
    setLoading,
    id,
    listUrl,
    parentId,
    parentType,
    listUrlSecond
  } = useEditSpecial<CourseFilterDto>(
    "coursefilter",
    mainObject,
    currentRoute,
    get
  );

  const onSubmit = (obj: CourseFilterDto) => {
    if (!obj) return;
    setLoading(true);

    obj.idcourse = parentId.toString();
    service.update(
      id,
      ComponentCommonTools.handleSuccessAddAndEditSpecial,
      {
        mainObject,
        parentType,
        parentId,
        specialPart: "coursefilter",
        cb: CommonTools.processLoadingCallback(setLoading),
      },
      obj
    );
  };
  if (!currentRoute) return null;
  if (!mainObject) return null;
  if (loading) return <Loading />;
  if (!object) return null;

  return (
    <React.Fragment>
      <HeaderEditSpecial
        mainObject={mainObject}
        mainUrl={mainUrl}
        detailUrl={detailUrl}
        object={object}
        parentId={parentId}
        parentType={parentType}
        specialType="coursefilter"
        listUrl={listUrl}
        listUrlSecond={listUrlSecond}
      />
      <DataDisplayBox>
        <Box>
          <FormCourseFilter
            defaultObject={object}
            onSubmit={onSubmit}
            loading={loading}
          />
        </Box>
      </DataDisplayBox>
    </React.Fragment>
  );
};

export { SpecialCourseFilterEdit };
